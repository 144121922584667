"use es6";

import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import P from "../Text/P";
import settings from "../../graphics/icons/settings.svg";
import activity from "../../graphics/icons/activity.svg";
import chevron from "../../graphics/icons/chevron.svg";
import avable_wordmark from "../../graphics/branding/avable_wordmark.svg";
import Button from "../Buttons/Button";
import HR from "../Text/HR";

const Header = ({
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  isDesktop = null,
  translation = null,
  activePage = null,
  setActivePage = {},
  children = "[insert text]",
  setAuthenticationViewportVisible = {},
  setSettingsViewportVisible = {},
  setNoticeViewportVisible = {},
  setActivityViewportVisible = {},
  ...buttonProps
}) => {
  const navigate = useNavigate();

  const location = useLocation();
  const username = location.pathname.substring(1);

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        height: 48,
        zIndex: 5,
        backgroundColor: "rgba(183, 81, 224,.975)",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#673794",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 5,
        }}
      >
        {!isDesktop && activePage === "profile" ? (
          <div style={{ width: 72 }}></div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {activePage === "" || isDesktop ? (
            <img
              src={avable_wordmark}
              style={{
                cursor: "pointer",
                marginTop: 2,
                height: 34,
                marginLeft: 6,
              }}
              onClick={() => {
                navigate("/");
                if (activePage === "chatrooms") {
                  window.location.reload();
                }
                setActivePage("");
              }}
            />
          ) : (
            <div style={{ display: "flex" }}>
              {activePage === "explore" && username !== "explore" ? (
                <div
                  onClick={() => {
                    navigate("/explore");
                  }}
                >
                  <img
                    src={chevron}
                    style={{ width: 24, height: 24, margin: 6 }}
                  />
                </div>
              ) : null}
              <P
                style={{
                  marginTop: 2,
                  marginLeft: 6,
                  color: "#ffffff",
                  fontSize: 26,
                  fontWeight: 700,
                }}
              >
                {activePage === "profile"
                  ? username
                  : translation.navigation[activePage]}
              </P>
            </div>
          )}
          {isDesktop ? (
            <div style={{ display: "flex" }}>
              <HR
                style={{
                  height: "100%",
                  width: 1,
                  marginLeft: 16,
                }}
              />
              <Button
                onClick={() => {
                  navigate(`/explore`);
                  if (activePage === "chatrooms") {
                    window.location.reload();
                  }
                  setActivePage("explore");
                }}
              >
                <P
                  style={{
                    color: "#ffffff",
                    padding: 8,
                    marginLeft: 8,
                    fontSize: 18,
                  }}
                >
                  {translation.navigation.explore}
                </P>
              </Button>

              {!!credentials ? (
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        navigate(`/${credentials.username}`);
                        if (activePage === "chatrooms") {
                          window.location.reload();
                        }
                        setActivePage("profile");
                      }}
                    >
                      <P
                        style={{
                          color: "#ffffff",
                          padding: 8,
                          fontSize: 18,
                        }}
                      >
                        {translation.navigation.profile}
                      </P>
                    </Button>
                    <Button
                      onClick={() => {
                        navigate("/chatrooms");
                        if (activePage === "chatrooms") {
                          window.location.reload();
                        }
                        setActivePage("chatrooms");
                      }}
                    >
                      <P
                        style={{
                          color: "#ffffff",
                          padding: 8,
                          fontSize: 18,
                        }}
                      >
                        {translation.navigation.chatrooms}
                      </P>
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!!credentials && username === credentials.username ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActivityViewportVisible(true);
              }}
            >
              <img
                src={activity}
                style={{ width: 24, height: 24, margin: 6 }}
              />
            </div>
          ) : null}
          {isDesktop ? (
            <Button
              onClick={() => {
                if (!!credentials) {
                  setNoticeViewportVisible("signout");
                } else {
                  setAuthenticationViewportVisible(true);
                  setSearchParams({ action: "verify" });
                }
              }}
            >
              <P style={{ color: "#ffffff", padding: 8, fontSize: 18 }}>
                {!!credentials
                  ? translation.authentication.sign_out
                  : translation.authentication.sign_in}
              </P>
            </Button>
          ) : null}
          {!isDesktop && !!credentials && username === credentials.username ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSettingsViewportVisible(true);
              }}
            >
              <img
                src={settings}
                style={{ width: 24, height: 24, margin: 6 }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
