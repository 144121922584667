"use es6";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import P from "../components/Text/P";
import H2 from "../components/Text/H2";
import SearchHub from "../components/SearchHub/SearchHub";
import Loading from "../components/Loading/Loading";
import Button from "../components/Buttons/Button";

const HomePage = ({
  isDesktop = null,
  translation = null,
  activePage = null,
  setActivePage = {},
  dimensions = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div style={{ flex: 1 }}>
      {!!translation && dimensions ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            maxWidth: isDesktop ? dimensions.width - 360 : null,
          }}
        >
          <div style={{ marginBottom: 20 }}>
            <SearchHub
              isDesktop={isDesktop}
              translation={translation}
              setActivePage={setActivePage}
            />
          </div>

          <div
            style={{
              textAlign: "left",
              margin: "auto",
              maxWidth: isDesktop ? 600 : dimensions.width - 20,
            }}
          >
            <iframe
              width="100%"
              height={isDesktop ? 295 : 210}
              src="https://www.youtube.com/embed/MD_CMrCpBMc?si=298QuH7ZVY494Nvk"
              title="YouTube video player"
              frameborder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <br />
            <br />
            <H2 style={{ marginBottom: 15 }}>
              {translation.homepage.mission_header}
            </H2>
            <P style={{ marginBottom: 30 }}>
              {translation.homepage.mission_body}
            </P>
            <H2 style={{ marginBottom: 15 }}>
              {translation.homepage.process_header}
            </H2>
            <P style={{ marginBottom: 10 }}>
              {translation.homepage.process_body}
            </P>
            <br />
            <Button
              type="solid"
              style={{ textAlign: "center", marginTop: 10, marginBottom: 20 }}
              onClick={() => {
                navigate("/policy");
                if (activePage === "chatrooms") {
                  window.location.reload();
                }
                setActivePage("");
              }}
            >
              {translation.navigation.privacy_policy}
            </Button>
          </div>
        </div>
      ) : (
        <Loading
          style={{
            position: "fixed",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
};

export default HomePage;
