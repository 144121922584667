"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import xmark from "../../graphics/icons/xmark.svg";
import P from "../Text/P";
import Button from "../Buttons/Button";
import HR from "../Text/HR";

const Activity = ({
  credentials = {},
  updateCredentials = {},
  translation = null,
  setActivityViewportVisible = {},
  activity = [],
  setLoadedActivities = {},
  setDeletedActivities = {},
  ...buttonProps
}) => {
  const navigate = useNavigate();

  const [sender, setSender] = useState(null);
  const [deleted, setDeleted] = useState(false);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getSender = async () => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${activity[1].sender}`,
        requestConfig
      )
      .then((res) => {
        setSender(res.data);
        setLoadedActivities((prevLoadedActivities) => prevLoadedActivities + 1);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getSender();
  }, []);

  const deleteActivity = async (id) => {
    const requestBody = {
      activityId: id,
      deleteActivitys: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        setDeleted(true);
        setDeletedActivities(
          (prevDeletedActivities) => prevDeletedActivities + 1
        );
      })
      .catch((error) => {});
  };

  const sendAcceptance = async (recipient) => {
    const requestBody = {
      action: {
        id: Date.now(),
        sender: credentials.id,
        type: "acceptance",
        data: {},
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${recipient}`,
        requestBody,
        requestConfig
      )
      .then(() => {
        let newConnections = { ...credentials.connections };
        newConnections[recipient] = true;

        axios
          .post(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
            { connections: newConnections },
            requestConfig
          )
          .then((res) => {
            if (res.data.account.id === credentials.id) {
              updateCredentials(res.data.account);
            }
            axios.post(
              `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${recipient}`,
              { connection: { [`${credentials.id}`]: true } },
              requestConfig
            );
          });
      })
      .catch((error) => {});
  };

  const sendRejection = async (recipient) => {
    const requestBody = {
      action: {
        id: Date.now(),
        sender: credentials.id,
        type: "rejection",
        data: {},
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${recipient}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        axios
          .get(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${recipient}`,
            requestConfig
          )
          .then((res2) => {
            let newConnections = { ...res2.data.connections };
            delete newConnections[credentials.id];
            axios
              .post(
                `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${recipient}`,
                { connections: newConnections },
                requestConfig
              )
              .catch((error) => {});
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  return !!sender && !deleted ? (
    <div>
      <HR />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <div style={{ display: "flex", alignContent: "center" }}>
          <img
            src={
              sender.image ||
              "https://icon-library.com/images/default-profile-icon/default-profile-icon-6.jpg"
            }
            style={{
              width: 48,
              height: 48,
              objectFit: "cover",
              borderWidth: 1,
              borderColor: "#673794",
              borderStyle: "solid",
              borderRadius: 48,
              margin: 10,
            }}
            onClick={() => {
              navigate(`/${sender.username}`);
              setActivityViewportVisible(false);
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.opacity = "50%";
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.opacity = "100%";
            }}
          />
          <P style={{ marginTop: 10 }}>
            @{sender.username}{" "}
            {activity[1].type === "request"
              ? translation.activity.request
              : activity[1].type === "acceptance"
              ? translation.activity.acceptance
              : activity[1].type === "rejection"
              ? translation.activity.rejection
              : activity[1].type === "nudge"
              ? translation.activity.nudge
              : activity[1].type === "announcement"
              ? translation.activity.announcement
              : ""}
          </P>
        </div>

        {activity[1].type === "request" ? (
          <div>
            <Button
              type="solid"
              style={{
                margin: 10,
                fontSize: 12,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
              }}
              onClick={() => {
                sendAcceptance(activity[1].sender);
                deleteActivity(activity[0]);
              }}
            >
              {translation.activity.accept}
            </Button>
            <Button
              type="solid"
              style={{
                margin: 10,
                fontSize: 12,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                backgroundColor: "#EB5757",
              }}
              onClick={() => {
                sendRejection(activity[1].sender);
                deleteActivity(activity[0]);
              }}
            >
              {translation.activity.reject}
            </Button>
          </div>
        ) : activity[1].type === "nudge" ? (
          <div>
            <Button
              type="solid"
              style={{
                margin: 10,
                fontSize: 12,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                backgroundColor: "rgba(183, 81, 224,.975)",
              }}
              onClick={() => {
                navigate(`/chatroom/?id=${sender.username}`);
                setActivityViewportVisible(false);
              }}
            >
              {translation.button.join}
            </Button>
          </div>
        ) : (
          <div
            onClick={() => {
              deleteActivity(activity[0]);
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.opacity = "50%";
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.opacity = "100%";
            }}
          >
            <img
              src={xmark}
              style={{
                width: 16,
                height: 16,
                margin: 10,
                marginTop: 12,
                filter: "brightness(0)",
              }}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Activity;
