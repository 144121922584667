import React from "react";
import Card from "../../Shared/Card/Card.component";
import { faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowDimensions } from "../../../utils/CustomHooks";
import "./Participant.css";

import english from "../../../translations/english.json";
import spanish from "../../../translations/spanish.json";
import french from "../../../translations/french.json";
import chinese from "../../../translations/chinese.json";

export const Participant = (props) => {
  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 910;

  const language = navigator.language;

  const translations = {
    en: english,
    es: spanish,
    fr: french,
    zh: chinese,
  };

  const translation = !!translations[language.substring(0, 2)].authentication
    ? translations[language.substring(0, 2)]
    : translations.en;

  const {
    curentIndex,
    currentParticipant,
    hideVideo,
    videoRef,
    showAvatar,
    currentUser,
  } = props;
  if (!currentParticipant) return <></>;
  return (
    <div
      className={`participant ${hideVideo ? "hide" : ""}`}
      style={{ width: "100%", margin: "auto" }}
    >
      <Card>
        <video
          ref={videoRef}
          className="video"
          id={`participantVideo${curentIndex}`}
          style={{
            transform:
              currentUser && !currentParticipant.screen
                ? "scaleX(-1)"
                : "scaleX(1)",
            width: !currentParticipant.screen
              ? "100%"
              : dimensions.width * 0.65,
          }}
          autoPlay
          playsInline
        ></video>
        {!currentParticipant.audio && (
          <FontAwesomeIcon
            className="muted"
            icon={faMicrophoneSlash}
            title="Muted"
          />
        )}
        {showAvatar && !!currentParticipant.image ? (
          <div
            style={{ background: currentParticipant.avatarColor }}
            className="avatar"
          >
            <img
              src={currentParticipant.image}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                textAlign: "center",
                objectFit: "cover",
              }}
            />
          </div>
        ) : showAvatar ? (
          <div
            style={{ background: currentParticipant.avatarColor }}
            className="avatar"
          >
            {!!currentParticipant.username
              ? currentParticipant.username[0]
              : currentParticipant.name[0]}
          </div>
        ) : null}
        <div className="name">
          {currentParticipant.username
            ? `@${currentParticipant.username}`
            : currentParticipant.name}
          {currentUser ? ` (${translation.miscellaneous.you})` : ""}
        </div>
      </Card>
    </div>
  );
};
