"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import chevron from "../../graphics/icons/chevron.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";

const SettingsViewport = ({
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  settingsViewportVisible = false,
  setSettingsViewportVisible = {},
  setPurchaseViewportVisible = {},
  setPasswordViewportVisible = {},
  setUpgradeViewportVisible = {},
  setNoticeViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 10,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#eeeff0",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          height: 48,
          zIndex: 5,
          backgroundColor: "rgba(183, 81, 224,.975)",
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          borderBottomColor: "#673794",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={() => {
            setSettingsViewportVisible(false);
          }}
        >
          <img
            src={chevron}
            style={{ width: 24, height: 24, margin: 10, marginTop: 12 }}
          />
        </div>
        <P
          style={{
            marginTop: 8,
            marginLeft: 6,
            color: "#ffffff",
            fontSize: 26,
            fontWeight: 700,
          }}
        >
          {translation.navigation.settings}
        </P>

        <div style={{ width: 24, height: 24, margin: 10, marginTop: 12 }} />
      </div>
      <div style={{ paddingTop: 50, backgroundColor: "#ffffff" }}>
        <Button
          style={{ padding: 20 }}
          onClick={() => {
            setPasswordViewportVisible(true);
          }}
        >
          <H3 style={{ fontWeight: 500 }}>
            {translation.authentication.change_password}
          </H3>
        </Button>
        <HR />

        <Button
          style={{ padding: 20 }}
          onClick={() => {
            setPurchaseViewportVisible(true);
          }}
        >
          <H3 style={{ fontWeight: 500 }}>
            {translation.navigation.purchase_credits}
          </H3>
        </Button>
        <HR />

        <Button
          style={{ padding: 20 }}
          onClick={() => {
            setUpgradeViewportVisible(true);
          }}
        >
          <H3 style={{ fontWeight: 500 }}>
            {translation.navigation.upgrade_account}
          </H3>
        </Button>
        <HR />

        <Button
          style={{ padding: 20 }}
          onClick={() => {
            setNoticeViewportVisible("signout");
          }}
        >
          <H3 style={{ fontWeight: 500 }}>
            {translation.authentication.sign_out}
          </H3>
        </Button>
        <HR />

        <Button
          style={{ padding: 20 }}
          onClick={() => {
            setNoticeViewportVisible("deleteaccount");
          }}
        >
          <H3 style={{ fontWeight: 500, color: "#EB5757" }}>
            {translation.authentication.delete_account}
          </H3>
        </Button>
        <HR />
      </div>

      <div
        style={{
          position: "absolute",
          margin: 10,
          width: "100%",
          textAlign: "center",
          bottom: 0,
          color: "#888888",
        }}
      >
        <P>Avable Co. LLC, 2023</P>
      </div>
    </div>
  );
};

export default SettingsViewport;
