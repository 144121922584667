"use es6";

import { useState, useEffect } from "react";
import { useWindowDimensions } from "../../utils/CustomHooks";
import Button from "../Buttons/Button";

import english from "../../translations/english.json";
import spanish from "../../translations/spanish.json";
import french from "../../translations/french.json";
import chinese from "../../translations/chinese.json";

const GameArea = ({
  currentUser,
  participantRef,
  gameRef,
  players,
  playerOrder,
  ingame,
}) => {
  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 910;

  const language = navigator.language;

  const translations = {
    en: english,
    es: spanish,
    fr: french,
    zh: chinese,
  };

  const translation = !!translations[language.substring(0, 2)].authentication
    ? translations[language.substring(0, 2)]
    : translations.en;

  const [playerTurnIndex, setPlayerTurnIndex] = useState(0);

  const currentPlayer = currentUser[Object.keys(currentUser)[0]];

  const [hand, setHand] = useState([]);

  const [discard, setDiscard] = useState([]);

  const [selectedCards, setSelectedCards] = useState([]);

  const [expectedCard, setExpectedCard] = useState([]);

  const numbers = [
    "A",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
  ];

  const suits = ["S", "C", "H", "D"];

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const createDeck = (suits, numbers) => {
    let tempDeck = [];
    for (let i = 0; i < suits.length; i++) {
      for (let j = 0; j < numbers.length; j++) {
        tempDeck.push(suits[i] + numbers[j]);
      }
    }
    return shuffle(tempDeck);
  };

  const distributeDeck = (deck, players) => {
    let result = [];
    for (let i = players; i > 0; i--) {
      result.push(deck.splice(0, Math.ceil(deck.length / i)));
    }
    return result;
  };

  const asyncForEachHands = async (hands) => {
    var responseArray = await Promise.all(
      hands.map(async (hand, index) => {
        gameRef.child("hands").update({ [`${playerOrder[index][0]}`]: hand });
        return `${playerOrder[index][0]}`;
      })
    );
    return responseArray;
  };

  useEffect(() => {
    const deck = createDeck(suits, numbers);
    const numPlayers = Object.keys(players).length;
    let leftovers = deck.length % numPlayers;
    let cleanDeck = deck.slice(0, deck.length - leftovers);
    const hands = distributeDeck(cleanDeck, numPlayers);

    asyncForEachHands(hands).then((res) => {
      console.log(res);

      const leftoverDeck = deck.slice(deck.length - leftovers, deck.length);
      gameRef.child("deck").update({
        discard: leftoverDeck.length === 0 ? "empty" : leftoverDeck,
      });
      console.log("here again lol");
      const startingCard = ["A"];
      gameRef.child("turns").update({ turnindex: 0, expecting: startingCard });
      setExpectedCard(startingCard);

      gameRef.child("hands").on("value", async (snap) => {
        const val = await snap.val();
        setHand(val[currentPlayer.username]);
      });
    });
  }, [ingame]);

  useEffect(() => {
    if (!!hand && hand.length !== 0) {
      gameRef.child("turns").on("value", async (snap) => {
        let playerTurnIndex = await snap.val().turnindex;
        let expectedCardValue = await snap.val().expecting;
        if (playerTurnIndex >= playerOrder.length) {
          playerTurnIndex = 0;
        }
        if (expectedCardValue == ["K"]) {
          expectedCardValue = ["A"];
        }
        setPlayerTurnIndex(playerTurnIndex);
        setExpectedCard(expectedCardValue);
      });
      gameRef.child("deck").on("value", async (snap) => {
        let gameDeck = await snap.val();
        if (!!gameDeck && !!gameDeck.discard) {
          setDiscard(gameDeck.discard);
        }
      });
    }
  }, [hand]);

  const playCards = async () => {
    if (selectedCards.length < 1) {
      alert(translation.games.must_select_cards);
    } else if (playerOrder[playerTurnIndex][0] === currentPlayer.username) {
      let newHand = hand.filter((item) => selectedCards.indexOf(item) === -1);
      let newDiscard = discard !== "empty" ? discard : [];
      newDiscard.unshift(...selectedCards);
      console.log(newDiscard);

      gameRef.child("deck").update({ discard: newDiscard });
      gameRef.child("hands").update({ [currentPlayer.username]: newHand });
      gameRef.child("turns").update({ turnindex: playerTurnIndex + 1 });
      gameRef.child("turns").update({
        lastplayed: {
          player: currentPlayer.username,
          cards: selectedCards,
          expected: expectedCard,
        },
      });
      gameRef.child("turns").update({
        expecting:
          expectedCard[0] === "A"
            ? ["2"]
            : expectedCard[0] === "10"
            ? ["J"]
            : expectedCard[0] === "J"
            ? ["Q"]
            : expectedCard[0] === "Q"
            ? ["K"]
            : expectedCard[0] === "K"
            ? ["A"]
            : [String(Number(expectedCard) + 1)],
      });
      setSelectedCards([]);
    } else {
      alert(translation.games.not_turn_yet);
    }
  };

  const callBS = async () => {
    console.log("BS Called");
    if (
      playerOrder[
        playerTurnIndex === 0 ? playerOrder.length - 1 : playerTurnIndex - 1
      ][0] === currentPlayer.username
    ) {
      alert(translation.games.cant_call_bs);
      return;
    } else {
      gameRef
        .child("turns")
        .child("lastplayed")
        .on("value", async (snap) => {
          let lastplay = await snap.val();

          let verification = lastplay.cards.filter(
            (item) => item.charAt(1) !== lastplay.expected[0]
          );
          console.log(lastplay);
          console.log(verification);
          if (verification.length === 0) {
            let newHand = hand;
            newHand = newHand.concat(discard);
            let newDiscard = "empty";
            gameRef
              .child("hands")
              .update({ [currentPlayer.username]: newHand });
            gameRef.child("deck").update({ discard: newDiscard });
          } else {
            //get player's hand
            let lastplayerHand = [];
            gameRef
              .child("hands")
              .child(lastplay.player)
              .on("value", async (snap) => {
                lastplayerHand = await snap.val();
                console.log(lastplayerHand);
              });
            let newHand = lastplayerHand.concat(discard);
            console.log(newHand);
            let newDiscard = "empty";
            gameRef.child("hands").update({ [lastplay.player]: newHand });
            gameRef.child("deck").update({ discard: newDiscard });
          }
        });
    }
  };

  return (
    <div style={{ display: "flex", padding: 10 }}>
      {!!currentPlayer && !!playerOrder.length ? (
        <div
          style={{
            position: "fixed",
            zIndex: 10,
            right: 10,
            left: !isDesktop && 15,
            backgroundColor: "#008800",
            flexWrap: "wrap",
            width: isDesktop ? "39%" : "calc(100% - 25px)",
            height: isDesktop ? dimensions.height - 140 : 300,
            flex: 1,
            borderRadius: 8,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 20,
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontStyle: "italic",
              color: "#ffff00",
            }}
          >
            {translation.games.expected_card} {expectedCard}
          </div>
          <div
            style={{
              position: "absolute",
              top: 60,
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#ffffff",
              backgroundColor: "rgba(0,0,0,.25)",
              padding: 4,
              borderRadius: 4,
              minWidth: 200,
              maxWidth: "80%",
              textAlign: "center",
            }}
          >
            @{playerOrder[playerTurnIndex][0]}
            {translation.games.players_turn}
          </div>
          <div
            style={{
              position: "absolute",
              top: "30%",
              left: "47.5%",
              transform: "translate(-25%, -25%)",
              backgroundColor: "red",
            }}
          >
            {!!discard && discard !== "empty"
              ? discard.map((card, index) => (
                  <div
                    key={index}
                    style={{
                      position: "absolute",
                      zIndex: index,
                      transform: `rotate(${
                        Math.floor(Math.random() * 30) - 15
                      }deg`,
                      borderRadius: 4,
                      borderWidth: 2,
                      borderColor: "#ffffff",
                      backgroundColor: "rgba(183, 81, 224,1)",
                      backgroundImage:
                        "linear-gradient(30deg, #ffffff 12%, transparent 12.5%, transparent 87%, #ffffff 87.5%, #ffffff), linear-gradient(150deg, #ffffff 12%, transparent 12.5%, transparent 87%, #ffffff 87.5%, #ffffff), linear-gradient(30deg, #ffffff 12%, transparent 12.5%, transparent 87%, #ffffff 87.5%, #ffffff), linear-gradient(150deg, #ffffff 12%, transparent 12.5%, transparent 87%, #ffffff 87.5%, #ffffff), linear-gradient(60deg, #ffffff77 25%, transparent 25.5%, transparent 75%, #ffffff77 75%, #ffffff77), linear-gradient(60deg, #ffffff77 25%, transparent 25.5%, transparent 75%, #ffffff77 75%, #ffffff77)",
                      backgroundSize: "2px 3px",
                      backgroundPosition:
                        "0 0, 0 0, 10px 18px, 10px 18px, 0 0, 10px 18px",
                      borderStyle: "solid",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.05)",
                      width: 26,
                      height: 31,
                    }}
                  />
                ))
              : null}
          </div>

          <div
            style={{
              position: "absolute",
              bottom: 6,
              width: "100%",
              backgroundColor: "rgba(0,0,0,.25)",
            }}
          >
            <div style={{ display: "flex" }}>
              <Button
                type="solid"
                style={{
                  fontSize: 14,
                  paddingTop: 5,
                  paddingRight: 10,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  width: 100,
                  margin: 6,
                }}
                onClick={() => {
                  playCards();
                }}
              >
                {translation.games.play_cards}
              </Button>

              <Button
                type="solid"
                style={{
                  fontSize: 14,
                  paddingTop: 5,
                  paddingRight: 10,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  width: 100,
                  margin: 6,
                }}
                onClick={() => {
                  callBS();
                }}
              >
                {translation.games.call_bs}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {hand.map((card, index) => (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    position: "relative",
                    color:
                      card.charAt(0) === "S" || card.charAt(0) === "C"
                        ? "#000000"
                        : "#ff0000",
                    width: !isDesktop ? 12 : hand.length > 26 ? 15 : 20,
                    height: !isDesktop ? 16 : hand.length > 26 ? 20 : 27,
                    fontSize: !isDesktop ? 8 : hand.length > 26 ? 10 : 12,
                    backgroundColor:
                      selectedCards.indexOf(card) !== -1
                        ? "rgba(255,255,255,.5)"
                        : "#ffffff",
                    padding: 2,
                    margin: !isDesktop ? 2 : hand.length > 26 ? 2 : 4,
                    borderRadius: 4,
                    borderColor:
                      selectedCards.indexOf(card) !== -1
                        ? "gold"
                        : "rgba(0,0,0,0)",
                    borderWidth: 2,
                    borderStyle: "solid",
                  }}
                  onMouseOver={({ currentTarget }) => {
                    currentTarget.style.opacity = "50%";
                  }}
                  onMouseOut={({ currentTarget }) => {
                    currentTarget.style.opacity = "100%";
                  }}
                  onClick={() => {
                    setSelectedCards((prevSelectedCards) =>
                      prevSelectedCards.indexOf(card) === -1
                        ? [...prevSelectedCards, card]
                        : prevSelectedCards.filter((item) => item !== card)
                    );
                  }}
                >
                  <div
                    style={{
                      margin: "auto",
                      transform: "translate(0%, 40%)",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <div>
                      {card
                        .substring(0, 1)
                        .replace("S", "♠️")
                        .replace("C", "♣️")
                        .replace("H", "♥️")
                        .replace("D", "♦️")}
                    </div>
                    <div> {card.substring(1)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GameArea;
