"use es6";

import MainScreen from "../components/MainScreen/MainScreen.component";
import firepadRef, { db, userData } from "../server/firebase";
import { useEffect, useState } from "react";
import {
  setMainStream,
  addParticipant,
  setUser,
  removeParticipant,
  updateParticipant,
  setGame,
} from "../store/actioncreator";
import { connect } from "react-redux";

import H1 from "../components/Text/H1";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import english from "../translations/english.json";
import spanish from "../translations/spanish.json";
import french from "../translations/french.json";
import chinese from "../translations/chinese.json";

import { useWindowDimensions } from "../utils/CustomHooks";

import avable_background from "../graphics/branding/avable_background.png";

const ChatroomPage = (props) => {
  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const location = useLocation();
  const navigate = useNavigate();

  const language = navigator.language;

  const translations = {
    en: english,
    es: spanish,
    fr: french,
    zh: chinese,
  };

  const translation = !!translations[language.substring(0, 2)].authentication
    ? translations[language.substring(0, 2)]
    : translations.en;

  if (!location.state) {
    window.history.replaceState(null, "Meet", "?id=" + firepadRef.key);
  } else {
    window.history.replaceState(null, "Meet", "?id=" + location.state.roomid);
    window.location.reload();
  }

  const userName = userData.name || "";
  const userUsername = userData.username || "";
  const userImage = userData.image || null;

  const getUserStream = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    return localStream;
  };

  const handleConnections = async () => {
    const credentials = await JSON.parse(
      localStorage.getItem("avable-credentials")
    );

    const urlparams = new URLSearchParams(window.location.search);
    const roomid = urlparams.get("id");

    if (!isNaN(Number(roomid))) {
      if (Number(firepadRef.key) > Number(Date.now()) * 10000) {
        alert(translation.chatrooms.invalid_room_id);
        navigate("/chatrooms");
        return;
      }
    } else {
      const admission = await checkProfile(roomid, credentials);

      if (!!admission) {
        if (admission === "invalid_passcode") {
          alert(translation.chatrooms.invalid_passcode);
          navigate("/chatrooms");
        }
        if (admission === "invalid_roomid") {
          alert(translation.chatrooms.invalid_room_id);
          navigate("/chatrooms");
        }

        return;
      }
    }

    const stream = await getUserStream();
    stream.getVideoTracks()[0].enabled = false;
    props.setMainStream(stream);
    connectedRef.on("value", (snap) => {
      if (snap.val()) {
        const defaultPreference = {
          audio: true,
          video: false,
          screen: false,
        };
        const userStatusRef = participantRef.push({
          userName,
          userUsername,
          userImage,
          preferences: defaultPreference,
        });
        props.setUser({
          [userStatusRef.key]: {
            name: userName,
            username: userUsername,
            image: userImage,
            ...defaultPreference,
          },
        });
        userStatusRef.onDisconnect().remove();
      }
    });
  };

  const checkProfile = async (username, credentials) => {
    let response = "";
    await axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${username}`,
        requestConfig
      )
      .then((res) => {
        if (!!res.data) {
          if (credentials.id !== res.data.id) {
            var passcode = prompt(translation.chatrooms.enter_passcode);
            if (passcode !== res.data.chatroompasscode) {
              response = "invalid_passcode";
            }
          }
        } else {
          response = "invalid_roomid";
        }
      })
      .catch((error) => {});
    return response;
  };

  useEffect(() => {
    handleConnections();
  }, []);

  const connectedRef = db.database().ref(".info/connected");
  const participantRef = firepadRef.child("participants");
  const gameRef = firepadRef.child("games");

  const isUserSet = !!props.user;
  const isStreamSet = !!props.stream;

  useEffect(() => {
    if (isStreamSet && isUserSet) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          props.updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          });
        });
        const {
          userName: name,
          userUsername: username,
          userImage: image,
          preferences = {},
        } = snap.val();
        props.addParticipant({
          [snap.key]: {
            name,
            username,
            image,
            ...preferences,
          },
        });
      });
      participantRef.on("child_removed", (snap) => {
        props.removeParticipant(snap.key);
      });
    }
  }, [isStreamSet, isUserSet]);

  useEffect(() => {
    gameRef.on("value", async (snap) => {
      if (snap.numChildren() === 0) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        gameRef.update({ activegame: "none" });
      }
    });
  }, []);

  return (
    <div style={{ marginTop: 10 }}>
      <MainScreen participantRef={participantRef} gameRef={gameRef} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    user: state.currentUser,
    game: state.game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    addParticipant: (user) => dispatch(addParticipant(user)),
    setUser: (user) => dispatch(setUser(user)),
    removeParticipant: (userId) => dispatch(removeParticipant(userId)),
    updateParticipant: (user) => dispatch(updateParticipant(user)),
    setGame: (game) => dispatch(setGame(game)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatroomPage);
