"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import xmark from "../../graphics/icons/xmark.svg";
import P from "../Text/P";
import Button from "../Buttons/Button";
import HR from "../Text/HR";

const Connection = ({
  credentials = {},
  updateCredentials = {},
  translation = null,
  isPreview = false,
  id = null,
  setLoadedActivities = {},
  setDeletedActivities = {},
  setActivePage = {},
  ...buttonProps
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [connection, setConnection] = useState(null);
  const [deleted, setDeleted] = useState(false);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getConnection = async () => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${id}`,
        requestConfig
      )
      .then((res) => {
        setConnection(res.data);
        setLoadedActivities((prevLoadedActivities) => prevLoadedActivities + 1);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getConnection();
  }, [id]);

  const deleteConnection = async (id) => {
    const requestBody = {
      activityId: id,
      deleteActivitys: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        setDeleted(true);
        setDeletedActivities(
          (prevDeletedActivities) => prevDeletedActivities + 1
        );
      })
      .catch((error) => {});
  };

  return !!connection && !deleted ? (
    <div>
      {isPreview ? (
        <div>
          <img
            src={
              connection.image ||
              "https://icon-library.com/images/default-profile-icon/default-profile-icon-6.jpg"
            }
            style={{
              width: 84,
              height: 84,
              objectFit: "cover",
              borderWidth: 1,
              borderColor: "#673794",
              borderStyle: "solid",
              borderRadius: 84,
              margin: 10,
            }}
            onClick={() => {
              navigate(`/${connection.username}`);
              setActivePage("explore");
              // setActivityViewportVisible(false);
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.opacity = "50%";
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.opacity = "100%";
            }}
          />
        </div>
      ) : (
        <div>
          <HR />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <div style={{ display: "flex", alignContent: "center" }}>
              <img
                src={
                  connection.image ||
                  "https://icon-library.com/images/default-profile-icon/default-profile-icon-6.jpg"
                }
                style={{
                  width: 48,
                  height: 48,
                  objectFit: "cover",
                  borderWidth: 1,
                  borderColor: "#673794",
                  borderStyle: "solid",
                  borderRadius: 48,
                  margin: 10,
                }}
                onClick={() => {
                  navigate(`/${connection.username}`);
                  setActivePage("explore");
                  // setActivityViewportVisible(false);
                }}
                onMouseOver={({ currentTarget }) => {
                  currentTarget.style.opacity = "50%";
                }}
                onMouseOut={({ currentTarget }) => {
                  currentTarget.style.opacity = "100%";
                }}
              />
              <P style={{ marginTop: 10 }}>@{connection.username} </P>
            </div>

            <Button
              type="solid"
              style={{
                margin: 10,
                fontSize: 12,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
              }}
              onClick={() => {
                navigate(`/chatroom/?id=${connection.username}`);
                // setActivityViewportVisible(false);
              }}
            >
              {translation.button.join}
            </Button>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default Connection;
