"use es6";

const H1 = ({ children = "[insert text]", style = {}, ...buttonProps }) => {
  return (
    <p style={{ fontSize: 36, margin: 0, ...style }} {...buttonProps}>
      {children}
    </p>
  );
};

export default H1;
