"use es6";

import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import Markdown from "react-markdown";
import H2 from "../components/Text/H2";
import P from "../components/Text/P";

import avable_logo_2 from "../graphics/branding/avable_logo_2.svg";

import english from "../articles/english.json";
import spanish from "../articles/spanish.json";
import french from "../articles/french.json";
import chinese from "../articles/chinese.json";

import { useWindowDimensions } from "../utils/CustomHooks";
import H1 from "../components/Text/H1";
import Button from "../components/Buttons/Button";

const ArticlePage = ({ translation }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const slug = location.pathname.substring(1).split("explore/")[1];

  const language = navigator.language;

  const article_translations = {
    en: english,
    es: spanish,
    fr: french,
    zh: chinese,
  };

  const articles = !!article_translations[language.substring(0, 2)]
    .authentication
    ? article_translations[language.substring(0, 2)]
    : article_translations.en;

  const article = articles.articles.filter((entry) => entry.slug === slug)[0];

  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 910;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div
      style={{
        margin: 20,
        marginTop: 20,
        maxWidth: isDesktop ? dimensions.width - 360 : null,
      }}
    >
      <div style={{ width: "85%", margin: "auto" }}>
        <H1>{article.title}</H1>
        <br />

        <P style={{ fontSize: 14 }}>
          {translation.explore.written_by} {article.author}
        </P>
        <P style={{ fontSize: 14 }}>
          {translation.explore.published}{" "}
          {new Date(article.date).toLocaleString(language, {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </P>
        <br />
        <P style={{ color: "#555555", fontStyle: "italic" }}>
          {article.description}
        </P>
        <br />
        <img
          src={article.image}
          style={{
            width: "80%",
            minWidth: 300,
            aspectRatio: 2,
            borderRadius: 8,
          }}
        />
        <br />
        <Markdown className="markdown">{article.body}</Markdown>
        <br />
        <br />
        <Button
          type="solid"
          style={{ textAlign: "center", marginBottom: 20 }}
          onClick={() => {
            navigate("/explore");
          }}
        >
          {translation.button.back}
        </Button>
      </div>
    </div>
  );
};

export default ArticlePage;
