"use es6";

import React, { useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import axios from "axios";
import { useComponentVisible } from "../../utils/CustomHooks";

import P from "../Text/P";
import Button from "../Buttons/Button";

const SearchBar = ({
  isDesktop = null,
  translation = null,
  sourceData = {},
  showSuggestions = true,
  setResult = {},
  light = false,
  browsingUsers = false,
  setActivePage = {},
  ...buttonProps
}) => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [suggestionsVisible, setSuggestionsVisible] = useState(true);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const searchFilter = (text) => {
    if (text) {
      const newData = sourceData.filter((item) => {
        const nameData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const numberData = item.number
          ? `+${item.number.countrycode} (${item.number.areacode}) ${item.number.prefix}-${item.number.subscriber}`
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return (
          nameData.indexOf(textData) !== -1 ||
          numberData.indexOf(textData) !== -1
        );
      });
      setFilteredData(newData.slice(0, 5));
    } else {
      setFilteredData(sourceData.slice(0, 5));
    }
    setSearch(text);
  };

  const browseFilter = (text) => {
    if (text) {
      axios
        .get(
          `${process.env.REACT_APP_ACCOUNTS_API_URL}/browsing/${text}`,
          requestConfig
        )
        .then((res) => {
          const data = res.data;
          data.sort(function (a, b) {
            var textA = a.username.toUpperCase();
            var textB = b.username.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          setFilteredData(res.data.slice(0, 5));
        })
        .catch((error) => {});
    }
    setSearch(text);
  };

  const ItemView = ({ item }) => {
    return (
      <div
        style={{ display: "flex", cursor: "pointer" }}
        onClick={() => {
          setSearch(`${item.username}`);
          if (browsingUsers) {
            navigate(`/${item.username}`);
            setActivePage("explore");
          } else {
            setResult(item);
          }
          setSuggestionsVisible(false);
        }}
      >
        <img
          src={
            item.image ||
            "https://icon-library.com/images/default-profile-icon/default-profile-icon-6.jpg"
          }
          style={{
            width: 32,
            height: 32,
            objectFit: "cover",
            borderWidth: 1,
            borderColor: "#673794",
            borderStyle: "solid",
            borderRadius: 32,
            margin: 10,
          }}
        />
        <P style={{ paddingTop: 16 }}>@{item.username}</P>
      </div>
    );
  };

  return !!translation ? (
    <div style={{}}>
      <div style={{ display: "flex" }}>
        <input
          type="text"
          style={{
            flex: 1,
            padding: 10,
            borderRadius: 8,
            backgroundColor: "#ffffff",
            borderStyle: "solid",
            borderWidth: 0.5,
            borderColor: "#ccc",
            color: light ? "#ffffff" : "#000000",
            fontSize: 16,
            marginRight: 10,
            minWidth: isDesktop ? 400 : 250,
            maxWidth: isDesktop ? 500 : 300,
          }}
          value={search}
          placeholder={translation.search_bar.search_cta}
          onChange={(event) => {
            setSuggestionsVisible(true);
            if (browsingUsers) {
              browseFilter(event.target.value);
            } else {
              searchFilter(event.target.value);
            }
            if (event.target.value.length === 0) {
              setFilteredData([]);
              setSuggestionsVisible(false);
            }
            setIsComponentVisible(true);
          }}
        />
        <Button
          type="solid"
          onClick={() => {
            navigate(`/${search}`);
            setActivePage("explore");
          }}
        >
          {translation.button.search}
        </Button>
      </div>
      <div ref={ref}>
        {showSuggestions &&
          isComponentVisible &&
          suggestionsVisible &&
          filteredData.length > 0 && (
            <div
              style={{
                width: "100%",
                maxWidth: isDesktop ? 400 : 250,
                marginLeft: 10,
                backgroundColor: "#ffffff",
                borderStyle: "solid",
                borderWidth: 0.5,
                borderColor: "#ccc",
                position: "absolute",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              {filteredData.map((data, index) => (
                <div>
                  <ItemView item={data} index={index} />
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  ) : null;
};

export default SearchBar;
