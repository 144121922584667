"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Button from "../Buttons/Button";
import Viewport from "./Viewport";
import Activity from "../Activitys/Activity";
import Loading from "../Loading/Loading";
import HR from "../Text/HR";

const ActivityViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  activityViewportVisible = false,
  setActivityViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const [activities, setActivities] = useState([]);

  const [loadedActivities, setLoadedActivities] = useState(0);
  const [deletedActivities, setDeletedActivities] = useState(0);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const getActivities = async () => {
    axios
      .get(
        `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${credentials.id}`,
        requestConfig
      )
      .then((res) => {
        const activityList = Object.keys(res.data.activitys)
          .map((key) => [Number(key), res.data.activitys[key]])
          .sort((a, b) => b[0] - a[0]);

        setActivities(activityList);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getActivities();
  }, []);

  const deleteAllActivity = async () => {
    const requestBody = {
      deleteAllActivitys: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {});
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.navigation.activity}
      viewportVisible={activityViewportVisible}
      setViewportVisible={setActivityViewportVisible}
    >
      <div style={{ height: 10 }} />
      <div
        style={{
          margin: "auto",
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "left",
          maxHeight: isDesktop ? 550 : "100%",
          overflowY: "scroll",
        }}
      >
        <Button
          style={{
            color: "rgba(183, 81, 224,.975)",
            marginLeft: 10,
          }}
          onClick={() => {
            deleteAllActivity();
          }}
        >
          {translation.button.clear_all}
        </Button>
        <br />

        <div
          style={{
            height: loadedActivities !== activities.length ? 0 : 550,
            opacity: loadedActivities !== activities.length && 0,
          }}
        >
          {activities.length > 0 && deletedActivities !== activities.length ? (
            activities.map((activity, index) => (
              <Activity
                key={index}
                credentials={credentials}
                updateCredentials={updateCredentials}
                translation={translation}
                setActivityViewportVisible={setActivityViewportVisible}
                activity={activity}
                setLoadedActivities={setLoadedActivities}
                setDeletedActivities={setDeletedActivities}
              />
            ))
          ) : (
            <div
              style={{
                margin: "auto",
                width: "100%",
                height: 550,
                textAlign: "center",
                marginTop: 100,
                color: "#888888",
              }}
            >
              {translation.miscellaneous.nothing_here}
            </div>
          )}
        </div>
        {loadedActivities !== activities.length ? (
          <div style={{ minHeight: 550 }}>
            <Loading
              style={{
                position: "fixed",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        ) : null}
      </div>
    </Viewport>
  );
};

export default ActivityViewport;
