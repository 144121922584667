"use es6";

const HR = ({ style = {} }) => {
  return (
    <hr
      style={{
        position: "relative",
        top: -2,
        opacity: 0.25,
        filter: "brightness(0%)",
        padding: 0,
        margin: 0,
        border: "none",
        height: 1,
        color: "rgba(0,0,0,.5)",
        backgroundColor: "rgba(0,0,0,.5)",
        ...style,
      }}
    />
  );
};

export default HR;
