"use es6";

import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

import H2 from "../components/Text/H2";
import Loading from "../components/Loading/Loading";

const ChatroomsPage = ({
  credentials = null,
  translation = null,
  isDesktop = null,
  dimensions = {},
}) => {
  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const navigate = useNavigate();

  const [isFinding, setIsFinding] = useState(null);
  const [findButtonHover, setFindButtonHover] = useState(null);

  const [searchParams] = useSearchParams();

  const scalePolitics = (politics) => {
    switch (politics) {
      case "liberal":
        return 0.25;
      case "conservative":
        return 0.75;
      case "moderate":
      case "apolitical_prefer_not":
        return 0.5;
      case "leftist":
        return 0;
      case "traditionalist":
        return 1;
      default:
        return NaN;
    }
  };

  const findChatroom = async () => {
    setIsFinding(true);

    const isViable =
      !!credentials.interests &&
      !!credentials.personality &&
      !!credentials.interests.avg &&
      !!credentials.personality.big5 &&
      !!credentials.personality.politics;

    if (!isViable) {
      alert(translation.chatrooms.viable_alert);
      setIsFinding(false);
      return;
    }

    const requestBody = {
      id: credentials.id,
      data: [
        Number((credentials.interests.avg[0] + 10) / 20).toFixed(10),
        Number((credentials.interests.avg[1] + 10) / 20).toFixed(10),
        Number(credentials.personality.big5.agreeableness).toFixed(10),
        Number(credentials.personality.big5.conscientiousness).toFixed(10),
        Number(credentials.personality.big5.extraversion).toFixed(10),
        Number(credentials.personality.big5.neuroticism).toFixed(10),
        Number(credentials.personality.big5.openness).toFixed(10),
        Number(scalePolitics(credentials.personality.politics)).toFixed(10),
      ],
      raw: {
        hometown: credentials.hometown.name,
        interests: Object.keys(credentials.interests)
          .filter((key) => key !== "avg")
          .reduce((obj, key) => {
            obj[key] = credentials.interests[key];
            return obj;
          }, {}),
        big5: credentials.personality.big5,
        politics: credentials.personality.politics,
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_CHATROOMS_API_URL}/chatrooms`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        navigate("/chatroom", {
          state: { roomid: res.data },
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {}, []);

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: "#eeeff0",
        overflowY: "scroll",
      }}
    >
      {!!translation && !!credentials ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: isDesktop ? dimensions.width - 360 : null,
            height: "60%",
            margin: "auto",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              borderRadius: 8,
              flex: 1,
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
              color: "rgba(183, 81, 224,.975)",
              backgroundColor: "rgba(255,255,255,.33)",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#673794",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.backgroundColor = "rgba(183, 81, 224,.975)";
              currentTarget.style.color = "#ffffff";
              setFindButtonHover(true);
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.backgroundColor = "rgba(255,255,255,.33)";
              currentTarget.style.color = "rgba(183, 81, 224,.975)";
              setFindButtonHover(false);
            }}
            onClick={async () => {
              findChatroom();
            }}
          >
            <H2
              style={{
                position: "relative",
                top: isFinding ? "40%" : "50%",
                transform: "translate(0%, -50%)",
              }}
            >
              {translation.chatrooms.find} {translation.chatrooms.a_chatroom}
            </H2>
            {isFinding ? (
              <Loading
                style={{
                  position: "relative",
                  filter: findButtonHover ? "brightness(1000%)" : null,
                  top: "40%",
                  width: 20,
                  height: 20,
                  zIndex: 20,
                  marginBottom: 20,
                }}
              />
            ) : null}
          </div>
          <div
            style={{
              cursor: "pointer",
              borderRadius: 8,
              flex: 1,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 20,
              color: "rgba(183, 81, 224,.975)",
              backgroundColor: "rgba(255,255,255,.33)",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#673794",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
            }}
            onMouseOver={({ currentTarget }) => {
              currentTarget.style.backgroundColor = "rgba(183, 81, 224,.975)";
              currentTarget.style.color = "#ffffff";
            }}
            onMouseOut={({ currentTarget }) => {
              currentTarget.style.backgroundColor = "rgba(255,255,255,.33)";
              currentTarget.style.color = "rgba(183, 81, 224,.975)";
            }}
            onClick={() => {
              navigate("/chatroom", {
                state: { roomid: credentials.username },
              });
            }}
          >
            <H2
              style={{
                position: "relative",
                top: "50%",
                transform: "translate(0%, -50%)",
              }}
            >
              {translation.chatrooms.open}{" "}
              {translation.chatrooms.designated_chatroom}
            </H2>
          </div>
        </div>
      ) : (
        <Loading
          style={{
            position: "fixed",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
};

export default ChatroomsPage;
