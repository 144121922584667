"use es6";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import P from "../components/Text/P";
import H2 from "../components/Text/H2";
import SearchHub from "../components/SearchHub/SearchHub";
import Loading from "../components/Loading/Loading";
import Button from "../components/Buttons/Button";
import SearchBar from "../components/SearchBar/SearchBar";
import Bold from "../components/Text/Bold";

import english from "../articles/english.json";
import spanish from "../articles/spanish.json";
import french from "../articles/french.json";
import chinese from "../articles/chinese.json";

import avable_wordmark from "../graphics/branding/avable_wordmark.svg";

const ExplorePage = ({
  isDesktop = null,
  translation = null,
  activePage = null,
  setActivePage = {},
  dimensions = null,
}) => {
  const navigate = useNavigate();

  const language = navigator.language;

  const translations = {
    en: english,
    es: spanish,
    fr: french,
    zh: chinese,
  };

  const [articles, setArticles] = useState(
    !!translations[language.substring(0, 2)].authentication
      ? translations[language.substring(0, 2)].articles
      : translations.en.articles
  );

  const [sortby, setSortby] = useState(null);

  const sort_by = ["latest", "popular", "editors_choice"];

  return (
    <div style={{ flex: 1 }}>
      {!!translation && dimensions ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            maxWidth: isDesktop ? dimensions.width - 360 : null,
          }}
        >
          <div
            style={{
              textAlign: "center",
              paddingTop: 10,
              paddingBottom: 20,
              margin: "auto",
              maxWidth: isDesktop ? 600 : dimensions.width - 20,
            }}
          >
            <P style={{ fontSize: 48, margin: 10, fontWeight: 600 }}>
              {translation.navigation.explore}
            </P>
            <P style={{ fontSize: 16, margin: 10 }}>
              {translation.explore.main_cta}
            </P>
            <br />
            <div
              style={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                height: 40,
              }}
            >
              <SearchBar
                isDesktop={isDesktop}
                translation={translation}
                browsingUsers={true}
                setActivePage={setActivePage}
              />
            </div>
          </div>
          <div style={{}}>
            <H2 style={{ marginBottom: 15 }}>{translation.explore.articles}</H2>
            <select
              style={{
                padding: 10,
                color: !!sortby ? "#ffffff" : "#000000",
                backgroundColor: !!sortby
                  ? "rgba(183, 81, 224,.975)"
                  : isDesktop
                  ? "#eeeff0"
                  : "#ffffff",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: !!sortby ? "#673794" : "#ccc",
                borderRadius: 8,
                fontSize: 14,
                marginTop: 5,
                width: 375,
              }}
              value={sortby}
              onChange={(event) => {
                setSortby(event.target.value);
                setArticles((prevArticles) =>
                  prevArticles.sort((a, b) => {
                    const value = `${
                      event.target.value === "latest"
                        ? "date"
                        : event.target.value === "popular"
                        ? "popularity"
                        : event.target.value === "editors_choice"
                        ? "rating"
                        : null
                    }`;
                    const keyA = a[`${value}`],
                      keyB = b[`${value}`];
                    if (value !== "date") {
                      if (keyA < keyB) return -1;
                      if (keyA > keyB) return 1;
                    } else {
                      if (keyA < keyB) return 1;
                      if (keyA > keyB) return -1;
                    }
                    return 0;
                  })
                );
              }}
            >
              {!sortby ? (
                <option value="" selected disabled hidden>
                  {translation.explore.sort_by}
                </option>
              ) : null}
              {sort_by.map((ordering, index) => (
                <option value={ordering} key={index}>
                  <div>{translation.explore[ordering]}</div>
                </option>
              ))}
            </select>

            <div
              style={{
                paddingTop: 10,
                display: "flex",
                flexWrap: "wrap",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {articles.map((article, index) => (
                <div
                  key={index}
                  style={{
                    cursor: "pointer",
                    margin: 10,
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: "#673794",
                    borderStyle: "solid",
                  }}
                  onMouseOver={({ currentTarget }) => {
                    currentTarget.style.opacity = "50%";
                  }}
                  onMouseOut={({ currentTarget }) => {
                    currentTarget.style.opacity = "100%";
                  }}
                  onClick={() => {
                    navigate(`/explore/${article.slug}`);
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(183, 81, 224,.975)",
                      borderRadius: 0,
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                    }}
                  >
                    <img
                      style={{
                        width: 300,
                        height: 150,
                        borderRadius: 0,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        objectFit: "cover",
                      }}
                      src={article.image || avable_wordmark}
                    />
                  </div>
                  <div
                    style={{
                      borderRadius: 0,
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                      backgroundColor: "#ffffff",
                      width: 300,
                      height: 125,
                      textAlign: "left",
                      borderTopColor: "673794",
                      borderTopWidth: 1,
                      borderTopStyle: "solid",
                    }}
                  >
                    <div style={{ padding: 10, color: "#000000" }}>
                      <P style={{ fontSize: 8 }}>
                        {new Date(article.date).toLocaleString(language, {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </P>
                      <Bold style={{ marginBottom: 5 }}>
                        {article.title.length > 80
                          ? `${article.title.substring(0, 77)}...`
                          : article.title}
                      </Bold>
                      <P
                        style={{
                          color: "#555555",
                          fontStyle: "italic",
                          fontSize: 12,
                        }}
                      >
                        {article.description.length > 90
                          ? `${article.description.substring(0, 87)}...`
                          : article.description}
                      </P>
                    </div>
                  </div>
                </div>
              ))}
              <br />
            </div>
          </div>
        </div>
      ) : (
        <Loading
          style={{
            position: "fixed",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
};

export default ExplorePage;
