"use es6";

import { useState, useEffect } from "react";
import axios from "axios";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { useWindowDimensions } from "./utils/CustomHooks";

import jwt_decode from "jwt-decode";

import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import ExplorePage from "./pages/ExplorePage";
import ArticlePage from "./pages/ArticlePage";
import ChatroomsPage from "./pages/ChatroomsPage";
import ChatroomPage from "./pages/ChatroomPage";
import PolicyPage from "./pages/PolicyPage";
import UnknownPage from "./pages/UnknownPage";

import english from "./translations/english.json";
import spanish from "./translations/spanish.json";
import french from "./translations/french.json";
import chinese from "./translations/chinese.json";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AuthenticationViewport from "./components/Viewports/AuthenticationViewport";
import SettingsViewport from "./components/Viewports/SettingsViewport";
import EditViewport from "./components/Viewports/EditViewport";
import PurchaseViewport from "./components/Viewports/PurchaseViewport";
import PasswordViewport from "./components/Viewports/PasswordViewport";
import UpgradeViewport from "./components/Viewports/UpgradeViewport";
import NoticeViewport from "./components/Viewports/NoticeViewport";
import BadgeViewport from "./components/Viewports/BadgeViewport";
import BadgesViewport from "./components/Viewports/BadgesViewport";
import InterestsViewport from "./components/Viewports/InterestsViewport";
import ConnectionsViewport from "./components/Viewports/ConnectionsViewport";
import ActivityViewport from "./components/Viewports/ActivityViewport";

import avable_background from "./graphics/branding/avable_background.png";

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [credentials, setCredentials] = useState(null);
  const [activePage, setActivePage] = useState(
    location.pathname.substring(1) === "" ||
      location.pathname.substring(1) === "policy"
      ? ""
      : location.pathname.indexOf("chatroom") !== -1
      ? "chatrooms"
      : location.pathname.substring(1) === "explore" ||
        location.pathname.indexOf("explore/") !== -1
      ? "explore"
      : !!credentials && location.pathname.substring(1) === credentials.username
      ? "profile"
      : ""
  );
  const [authenticationViewportVisible, setAuthenticationViewportVisible] =
    useState(false);
  const [settingsViewportVisible, setSettingsViewportVisible] = useState(false);
  const [editViewportVisible, setEditViewportVisible] = useState(false);
  const [uploadViewportVisible, setUploadViewportVisible] = useState(false);
  const [mapViewportVisible, setMapViewportVisible] = useState(false);
  const [purchaseViewportVisible, setPurchaseViewportVisible] = useState(false);
  const [passwordViewportVisible, setPasswordViewportVisible] = useState(false);
  const [upgradeViewportVisible, setUpgradeViewportVisible] = useState(false);
  const [noticeViewportVisible, setNoticeViewportVisible] = useState(false);
  const [contactViewportVisible, setContactViewportVisible] = useState(false);
  const [badgeViewportVisible, setBadgeViewportVisible] = useState(false);
  const [badgesViewportVisible, setBadgesViewportVisible] = useState(false);
  const [interestsViewportVisible, setInterestsViewportVisible] =
    useState(false);
  const [connectionsViewportVisible, setConnectionsViewportVisible] =
    useState(false);
  const [activityViewportVisible, setActivityViewportVisible] = useState(false);

  const [editTimestamp, setEditTimestamp] = useState(Date.now());

  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 910;

  const language = navigator.language;

  const translations = {
    en: english,
    es: spanish,
    fr: french,
    zh: chinese,
  };

  const translation = !!translations[language.substring(0, 2)].authentication
    ? translations[language.substring(0, 2)]
    : translations.en;

  const getCredentials = async () => {
    const data = await JSON.parse(localStorage.getItem("avable-credentials"));
    if (data) {
      setCredentials(data);
    }
  };

  const updateCredentials = async (data) => {
    setCredentials(data);
    localStorage.setItem("avable-credentials", JSON.stringify(data));
  };

  useEffect(() => {
    getCredentials();
  }, []);

  useEffect(() => {
    setSettingsViewportVisible(false);
  }, [isDesktop]);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  // }, [activePage]);

  return (
    <div
      style={{
        height: dimensions.height,
        margin: "auto",
        background: `url(${avable_background})`,
        boxShadow: "inset 0 0 0 2000px rgba(238, 239, 240, 0.975)",
        backgroundRepeat: "repeat",
      }}
    >
      {!!authenticationViewportVisible ||
      (searchParams.get("action") === "verify" && !credentials) ? (
        <AuthenticationViewport
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          authenticationViewportVisible={authenticationViewportVisible}
          setAuthenticationViewportVisible={setAuthenticationViewportVisible}
          setNoticeViewportVisible={setNoticeViewportVisible}
        />
      ) : null}
      {!!settingsViewportVisible ? (
        <SettingsViewport
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          settingsViewportVisible={settingsViewportVisible}
          setSettingsViewportVisible={setSettingsViewportVisible}
          setPurchaseViewportVisible={setPurchaseViewportVisible}
          setPasswordViewportVisible={setPasswordViewportVisible}
          setUpgradeViewportVisible={setUpgradeViewportVisible}
          setNoticeViewportVisible={setNoticeViewportVisible}
        />
      ) : null}
      {!!editViewportVisible ? (
        <EditViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          editViewportVisible={editViewportVisible}
          setEditViewportVisible={setEditViewportVisible}
          uploadViewportVisible={uploadViewportVisible}
          setUploadViewportVisible={setUploadViewportVisible}
          mapViewportVisible={mapViewportVisible}
          setMapViewportVisible={setMapViewportVisible}
          setEditTimestamp={setEditTimestamp}
        />
      ) : null}
      {!!purchaseViewportVisible ? (
        <PurchaseViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          purchaseViewportVisible={purchaseViewportVisible}
          setPurchaseViewportVisible={setPurchaseViewportVisible}
        />
      ) : null}
      {!!passwordViewportVisible ? (
        <PasswordViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          passwordViewportVisible={passwordViewportVisible}
          setPasswordViewportVisible={setPasswordViewportVisible}
        />
      ) : null}
      {!!upgradeViewportVisible ? (
        <UpgradeViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          upgradeViewportVisible={upgradeViewportVisible}
          setUpgradeViewportVisible={setUpgradeViewportVisible}
        />
      ) : null}
      {!!noticeViewportVisible ? (
        <NoticeViewport
          type={noticeViewportVisible}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          noticeViewportVisible={noticeViewportVisible}
          setNoticeViewportVisible={setNoticeViewportVisible}
        />
      ) : null}
      {!!badgeViewportVisible ? (
        <BadgeViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          badgeViewportVisible={badgeViewportVisible}
          setBadgeViewportVisible={setBadgeViewportVisible}
        />
      ) : null}
      {!!badgesViewportVisible ? (
        <BadgesViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          badgesViewportVisible={badgesViewportVisible}
          setBadgesViewportVisible={setBadgesViewportVisible}
        />
      ) : null}
      {!!interestsViewportVisible ? (
        <InterestsViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          interestsViewportVisible={interestsViewportVisible}
          setInterestsViewportVisible={setInterestsViewportVisible}
          setEditTimestamp={setEditTimestamp}
        />
      ) : null}
      {!!connectionsViewportVisible ? (
        <ConnectionsViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setSettingsViewportVisible={setSettingsViewportVisible}
          connectionsViewportVisible={connectionsViewportVisible}
          setConnectionsViewportVisible={setConnectionsViewportVisible}
        />
      ) : null}
      {!!activityViewportVisible ? (
        <ActivityViewport
          isDesktop={isDesktop}
          credentials={credentials}
          setCredentials={setCredentials}
          updateCredentials={updateCredentials}
          language={language}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          activityViewportVisible={activityViewportVisible}
          setActivityViewportVisible={setActivityViewportVisible}
        />
      ) : null}

      <Header
        credentials={credentials}
        setCredentials={setCredentials}
        updateCredentials={updateCredentials}
        isDesktop={isDesktop}
        dimensions={dimensions}
        translation={translation}
        activePage={activePage}
        setActivePage={setActivePage}
        setAuthenticationViewportVisible={setAuthenticationViewportVisible}
        setSettingsViewportVisible={setSettingsViewportVisible}
        setPurchaseViewportVisible={setPurchaseViewportVisible}
        setPasswordViewportVisible={setPasswordViewportVisible}
        setUpgradeViewportVisible={setUpgradeViewportVisible}
        setNoticeViewportVisible={setNoticeViewportVisible}
        setActivityViewportVisible={setActivityViewportVisible}
      />

      <div
        style={{
          paddingTop: 50,
          paddingBottom: !isDesktop && 50,
          display: isDesktop ? "flex" : "block",
          justifyContent: "space-between",
        }}
      >
        {activePage !== "chatrooms" ? (
          <div style={{ marginTop: 20, width: 160, textAlign: "center" }}></div>
        ) : null}

        <div
          style={{
            width:
              isDesktop && activePage !== "chatrooms"
                ? dimensions.width - 360
                : "100%",
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                <HomePage
                  isDesktop={isDesktop}
                  credentials={credentials}
                  setCredentials={setCredentials}
                  updateCredentials={updateCredentials}
                  dimensions={dimensions}
                  translation={translation}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              }
            />
            <Route
              path="/explore"
              element={
                <ExplorePage
                  isDesktop={isDesktop}
                  credentials={credentials}
                  setCredentials={setCredentials}
                  updateCredentials={updateCredentials}
                  dimensions={dimensions}
                  translation={translation}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              }
            />
            <Route path="/explore">
              <Route
                path=":article_slug"
                element={
                  <ArticlePage
                    isDesktop={isDesktop}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    dimensions={dimensions}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                }
              />
            </Route>
            <Route
              path="/policy"
              element={
                <PolicyPage
                  isDesktop={isDesktop}
                  credentials={credentials}
                  setCredentials={setCredentials}
                  updateCredentials={updateCredentials}
                  dimensions={dimensions}
                  translation={translation}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              }
            />
            <Route
              path=":username"
              element={
                !!credentials ? (
                  <ProfilePage
                    isDesktop={isDesktop}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    dimensions={dimensions}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    setEditViewportVisible={setEditViewportVisible}
                    setPurchaseViewportVisible={setPurchaseViewportVisible}
                    setPasswordViewportVisible={setPasswordViewportVisible}
                    setUpgradeViewportVisible={setUpgradeViewportVisible}
                    setNoticeViewportVisible={setNoticeViewportVisible}
                    setContactViewportVisible={setContactViewportVisible}
                    setBadgeViewportVisible={setBadgeViewportVisible}
                    setBadgesViewportVisible={setBadgesViewportVisible}
                    setInterestsViewportVisible={setInterestsViewportVisible}
                    setConnectionsViewportVisible={
                      setConnectionsViewportVisible
                    }
                    editTimestamp={editTimestamp}
                  />
                ) : (
                  <AuthenticationViewport
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    language={language}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    authenticationViewportVisible={true}
                    setAuthenticationViewportVisible={
                      setAuthenticationViewportVisible
                    }
                    setNoticeViewportVisible={setNoticeViewportVisible}
                  />
                )
              }
            />
            <Route
              path="/chatrooms"
              element={
                !!credentials ? (
                  <ChatroomsPage
                    isDesktop={isDesktop}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    dimensions={dimensions}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                ) : (
                  <AuthenticationViewport
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    language={language}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    authenticationViewportVisible={true}
                    setAuthenticationViewportVisible={
                      setAuthenticationViewportVisible
                    }
                    setNoticeViewportVisible={setNoticeViewportVisible}
                  />
                )
              }
            />
            <Route
              path="/chatroom"
              element={
                !!translation ? (
                  <ChatroomPage
                    isDesktop={isDesktop}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    updateCredentials={updateCredentials}
                    dimensions={dimensions}
                    translation={translation}
                    activePage={activePage}
                    setActivePage={setActivePage}
                  />
                ) : null
              }
            />

            <Route path="*" element={<UnknownPage />} />
          </Routes>
        </div>
        {activePage !== "chatrooms" ? (
          <div style={{ marginTop: 20, width: 160, textAlign: "center" }}></div>
        ) : null}
      </div>
      {!isDesktop ? (
        <Footer
          credentials={credentials}
          dimensions={dimensions}
          translation={translation}
          activePage={activePage}
          setActivePage={setActivePage}
          setAuthenticationViewportVisible={setAuthenticationViewportVisible}
          setActivityViewportVisible={setActivityViewportVisible}
        />
      ) : null}
    </div>
  );
}

export default App;
