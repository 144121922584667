"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import xmark from "../../graphics/icons/xmark.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Loading from "../Loading/Loading";

const NoticeViewport = ({
  type = null,
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  setActivePage = {},
  setSettingsViewportVisible = {},
  noticeViewportVisible = false,
  setNoticeViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const [deletingAccount, setDeletingAccount] = useState(false);
  const [email, setEmail] = useState("");
  const [requestingEmail, setRequestingEmail] = useState(false);

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const handleDeleteAccount = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        { deleting: true, deleteimage: credentials.image },
        requestConfig
      )
      .then(() => {
        const requestBody = {
          deleteAllActivitys: true,
        };
        axios
          .post(
            `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${credentials.id}`,
            requestBody,
            requestConfig
          )
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const disconnect = async (id) => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${id}`,
        requestConfig
      )
      .then((res) => {
        let newConnections = { ...res.data.connections };
        delete newConnections[credentials.id];
        axios
          .post(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${id}`,
            { connections: newConnections },
            requestConfig
          )
          .then(() => {
            let newConnections = { ...credentials.connections };
            delete newConnections[id];
            axios
              .post(
                `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
                { connections: newConnections },
                requestConfig
              )
              .then((res) => {
                if (res.data.account.id === credentials.id) {
                  updateCredentials(res.data.account);
                }
                setNoticeViewportVisible(false);
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  const handleForgotPassword = async (to) => {
    setRequestingEmail(true);
    const otp = Math.random().toString(36).slice(2, 10);

    await axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${to}`,
        requestConfig
      )
      .then(async (res) => {
        await axios
          .post(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${res.data.id}`,
            {
              oldpassword: null,
              password: otp,
            },
            requestConfig
          )
          .then(async () => {
            await axios.post(
              `${process.env.REACT_APP_EMAILS_API_URL}/authenticate`,
              { message: "reset", to: to, otp: otp },
              requestConfig
            );
            setNoticeViewportVisible(false);
            alert(translation.authentication.recovery_email_requested);
            setRequestingEmail(false);
          })
          .catch((error) => {
            alert(translation.authentication.invalid_email_address);
            setRequestingEmail(false);
          });
      });
  };

  window.onclick = function (event) {
    if (event.target.id == "backdrop") {
      setNoticeViewportVisible(false);
    }
  };

  return (
    <div
      id="backdrop"
      style={{
        position: "fixed",
        zIndex: 60,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.10)",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "50%",
          right: "50%",
          bottom: "50%",
          left: "50%",
          margin: "auto",
          backgroundColor: "#ffffff",
          borderRadius: 8,
          width: "90%",
          maxWidth: 400,
          display: "table",
          transform: "translate(-50%, 0)",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "rgba(183, 81, 224,.975)",
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            borderBottomColor: "#673794",
          }}
        >
          <div
            onClick={() => {
              setNoticeViewportVisible(false);
            }}
          >
            <img
              src={xmark}
              style={{ width: 24, height: 24, margin: 10, marginTop: 12 }}
            />
          </div>
          <P
            style={{
              marginTop: 8,
              marginLeft: 6,
              color: "#ffffff",
              fontSize: 26,
              fontWeight: 700,
            }}
          >
            {type === "signout"
              ? translation.authentication.sign_out
              : type === "deleteaccount"
              ? translation.authentication.delete_account
              : type === "forgotpassword"
              ? translation.authentication.forgot_password
              : type.split(":")[0] === "disconnect"
              ? translation.profile.disconnect
              : null}
            ?
          </P>
          <div style={{ width: 24, height: 24, margin: 10, marginTop: 12 }} />
        </div>
        <P style={{ margin: 20 }}>
          {type === "deleteaccount"
            ? translation.authentication.delete_account_string
            : type === "forgotpassword"
            ? translation.authentication.forgot_password_string
            : null}{" "}
          {type === "signout"
            ? translation.authentication.sign_out_confirmation
            : type === "deleteaccount"
            ? translation.authentication.delete_account_confirmation
            : type.split(":")[0] === "disconnect"
            ? translation.profile.disconnect_confirmation
            : null}
        </P>
        {type === "forgotpassword" ? (
          <input
            className="textinput"
            style={{
              width: 340,
              marginTop: 20,
              marginLeft: 20,
              marginRight: 20,
              padding: 10,
              borderStyle: "solid",
              borderRadius: 8,
              borderWidth: 0.5,
              borderColor: "#cccccc",
              backgroundColor: "#eeeff0",
            }}
            type="text"
            value={email}
            placeholder={translation.authentication.email}
            maxLength="24"
            onChange={(event) => setEmail(event.target.value)}
          />
        ) : null}
        <div
          style={{
            display: "flex",
            margin: 20,
            justifyContent: "space-between",
          }}
        >
          <Button
            type="solid"
            style={{
              width: 125,
              opacity: deletingAccount || requestingEmail ? "50%" : "100%",
            }}
            onClick={() => {
              if (!deletingAccount && !requestingEmail) {
                setNoticeViewportVisible(false);
              }
            }}
          >
            {translation.button.cancel}
          </Button>
          {type === "signout" ? (
            <Button
              type="solid"
              style={{
                backgroundColor: "rgba(183, 81, 224,.975)",
                width: 125,
              }}
              onClick={() => {
                setSettingsViewportVisible(false);
                setNoticeViewportVisible(false);
                updateCredentials(null);
                setActivePage("");
                navigate("/");
              }}
            >
              {translation.authentication.sign_out}
            </Button>
          ) : type === "deleteaccount" ? (
            <Button
              type="solid"
              style={{
                backgroundColor: "#EB5757",
                width: 125,
                opacity: deletingAccount ? "50%" : "100%",
              }}
              onClick={async () => {
                if (!deletingAccount) {
                  setDeletingAccount(true);
                  await handleDeleteAccount();
                  setSettingsViewportVisible(false);
                  setNoticeViewportVisible(false);
                  updateCredentials(null);
                  setActivePage("");
                  navigate("/");
                }
              }}
            >
              {translation.authentication.delete_account}
            </Button>
          ) : type === "forgotpassword" ? (
            <Button
              type="solid"
              style={{
                backgroundColor: "rgba(183, 81, 224,.975)",
                width: 125,
                opacity: requestingEmail ? "50%" : "100%",
              }}
              onClick={async () => {
                if (!requestingEmail) {
                  handleForgotPassword(email);
                }
              }}
            >
              {translation.button.request}
            </Button>
          ) : type.split(":")[0] === "disconnect" ? (
            <Button
              type="solid"
              style={{ backgroundColor: "#EB5757", width: 125 }}
              onClick={async () => {
                disconnect(type.split(":")[1]);
              }}
            >
              {translation.profile.disconnect}
            </Button>
          ) : null}
        </div>
        {deletingAccount ? (
          <P
            style={{ color: "#888888", textAlign: "center", marginBottom: 10 }}
          >
            {translation.authentication.deleting_account}
          </P>
        ) : null}
        {requestingEmail ? (
          <Loading
            style={{
              width: 20,
              height: 20,
              zIndex: 20,
              marginTop: 0,
              marginBottom: 10,
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default NoticeViewport;
