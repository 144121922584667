import React, { useState, useEffect, useRef } from "react";
import "./Participants.css";
import { connect } from "react-redux";
import { Participant } from "./Participant/Participant.component";
import { useWindowDimensions } from "../../utils/CustomHooks";

const Participants = (props) => {
  // const [ingame, setIngame] = useState(false);

  // useEffect(() => {
  //   props.gameRef.child("activegame").on("value", async (snap) => {
  //     const game = await snap.val();
  //     console.log(game);
  //     if (game !== "none") {
  //       setIngame(true);
  //     } else {
  //       setIngame(false);
  //     }
  //   });
  // }, []);

  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 910;

  const videoRef = useRef(null);
  let participantKey = Object.keys(props.participants);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = props.stream;
      videoRef.current.muted = true;
    }
  }, [props.currentUser, props.stream]);

  const currentUser = props.currentUser
    ? Object.values(props.currentUser)[0]
    : null;

  let gridCol =
    participantKey.length === 1 ? 1 : participantKey.length <= 4 ? 2 : 4;
  const gridColSize = isDesktop
    ? participantKey.length <= 4
      ? 1
      : 2
    : participantKey.length <= 2
    ? 1
    : 2;
  let gridRowSize =
    participantKey.length <= 4
      ? participantKey.length
      : Math.ceil(participantKey.length / 2);

  const screenPresenter = participantKey.find((element) => {
    const currentParticipant = props.participants[element];
    return currentParticipant.screen;
  });

  if (screenPresenter) {
    gridCol = 1;
    gridRowSize = 2;
  }
  const participants = participantKey.map((element, index) => {
    const currentParticipant = props.participants[element];
    const isCurrentUser = currentParticipant.currentUser;
    if (isCurrentUser) {
      return null;
    }
    const pc = currentParticipant.peerConnection;
    const remoteStream = new MediaStream();
    let curentIndex = index;
    if (pc) {
      pc.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });
        const videElement = document.getElementById(
          `participantVideo${curentIndex}`
        );
        if (videElement) videElement.srcObject = remoteStream;
      };
    }

    return (
      <Participant
        key={curentIndex}
        currentParticipant={currentParticipant}
        curentIndex={curentIndex}
        hideVideo={screenPresenter && screenPresenter !== element}
        showAvatar={
          !currentParticipant.video &&
          !currentParticipant.screen &&
          currentParticipant.name
        }
      />
    );
  });

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: isDesktop
          ? participants.length !== 2
            ? dimensions.width * 0.65
            : null
          : null,
        top:
          isDesktop && participants.length > 1
            ? (dimensions.height - 140) / 2
            : null,
        transform:
          isDesktop && participants.length > 1 ? "translate(0, -50%)" : null,
        margin: "auto",
        overflowY: "scroll",
        "--grid-size": gridCol,
        "--grid-col-size": gridColSize,
        "--grid-row-size": gridRowSize,
      }}
      className={`participants`}
    >
      {/* {JSON.stringify(participants.length)} */}
      <Participant
        currentParticipant={currentUser}
        curentIndex={participantKey.length}
        hideVideo={screenPresenter && !currentUser.screen}
        videoRef={videoRef}
        showAvatar={currentUser && !currentUser.video && !currentUser.screen}
        currentUser={true}
      />
      {participants}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    participants: state.participants,
    currentUser: state.currentUser,
    stream: state.mainStream,
    game: state.game,
  };
};

export default connect(mapStateToProps)(Participants);
