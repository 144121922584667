"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import chevron from "../../graphics/icons/chevron.svg";
import xmark from "../../graphics/icons/xmark.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";

const Viewport = ({
  isDesktop = null,
  heading = null,
  children = "[insert text]",
  viewportVisible = false,
  setViewportVisible = {},
}) => {
  window.onclick = function (event) {
    if (event.target.id == "backdrop") {
      setViewportVisible(false);
    }
  };
  return isDesktop ? (
    <div
      id="backdrop"
      style={{
        position: "fixed",
        zIndex: 60,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.10)",
      }}
    >
      <div
        style={{
          zIndex: 65,
          position: "fixed",
          top: "50%",
          right: "50%",
          bottom: "50%",
          left: "50%",
          margin: "auto",
          backgroundColor: "#ffffff",
          borderRadius: 8,
          width: "90%",
          maxWidth: 1000,
          display: "table",
          transform: "translate(-50%, 0)",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "rgba(183, 81, 224,.975)",
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            borderBottomColor: "#673794",
          }}
        >
          <div
            onClick={() => {
              setViewportVisible(false);
            }}
          >
            <img
              src={xmark}
              style={{ width: 24, height: 24, margin: 10, marginTop: 12 }}
            />
          </div>
          <P
            style={{
              marginTop: 8,
              marginLeft: 6,
              color: "#ffffff",
              fontSize: 26,
              fontWeight: 700,
            }}
          >
            {heading}
          </P>
          <div style={{ width: 24, height: 24, margin: 10, marginTop: 12 }} />
        </div>

        <div style={{ marginBottom: 20, marginTop: 0 }}>{children}</div>
      </div>
    </div>
  ) : (
    <div
      style={{
        position: "fixed",
        zIndex: 60,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#eeeff0",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          height: 48,
          zIndex: 65,
          backgroundColor: "rgba(183, 81, 224,.975)",
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          borderBottomColor: "#673794",
          display: "flex",
          justifyContent: "space-between",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          onClick={() => {
            setViewportVisible(false);
          }}
        >
          <img
            src={chevron}
            style={{ width: 24, height: 24, margin: 10, marginTop: 12 }}
          />
        </div>
        <P
          style={{
            marginTop: 8,
            marginLeft: 6,
            color: "#ffffff",
            fontSize: 26,
            fontWeight: 700,
          }}
        >
          {heading}
        </P>
        <div style={{ width: 24, height: 24, margin: 10, marginTop: 12 }} />
      </div>
      <div
        style={{
          marginBottom: 0,
          marginTop: 50,
          height: "100%",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Viewport;
