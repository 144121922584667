import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faVideo,
  faDesktop,
  faVideoSlash,
  faMicrophoneSlash,
  faEyeSlash,
  faDoorOpen,
  faChain,
  faCheck,
  faGamepad,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useWindowDimensions } from "../../utils/CustomHooks";
import "./MeetingFooter.css";

const MeetingFooter = (props) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 910;

  const [copying, setCopying] = useState(false);
  const [activegame, setActivePage] = useState("none");

  const [streamState, setStreamState] = useState({
    mic: true,
    video: false,
    screen: false,
  });

  const micClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        mic: !currentState.mic,
      };
    });
  };

  const onVideoClick = () => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        video: !currentState.video,
      };
    });
  };

  const onScreenClick = () => {
    if (!streamState.screen) {
      props.onScreenClick(setScreenState);
      setStreamState((currentState) => {
        return {
          ...currentState,
          screen: true,
        };
      });
    } else {
      props.onScreenShareEnd(setStreamState);
      setStreamState((currentState) => {
        return {
          ...currentState,
          screen: false,
        };
      });

      props.onVideoClick(true);
    }
  };

  const onGameClick = () => {
    props.gameRef.child("activegame").on("value", async (snap) => {
      setActivePage(snap.val());
    });
    if (activegame === "none") {
      props.gameRef.update({ activegame: "cheat" });
      props.participantRef.on("value", async (snap) => {
        props.setPlayers(await snap.val());
        let order = Array.from(
          Object.values(await snap.val()),
          (participant) => [participant.userUsername, participant.userName]
        );
        props.setPlayerOrder(order);
        props.setIngame(true);
      });
    } else {
      props.gameRef.update({ activegame: "none" });
    }
  };

  const setScreenState = (isEnabled) => {
    setStreamState((currentState) => {
      return {
        ...currentState,
        screen: isEnabled,
      };
    });
  };

  useEffect(() => {
    props.onMicClick(streamState.mic);
  }, [streamState.mic]);

  useEffect(() => {
    props.onVideoClick(streamState.video);
  }, [streamState.video]);

  useEffect(() => {
    props.gameRef.child("activegame").on("value", async (snap) => {
      setActivePage(snap.val());
    });
  }, []);

  const copyLink = async () => {
    setCopying(true);
    const url = `https://www.avable.co/chatroom/?id=${searchParams.get("id")}`;
    navigator.clipboard.writeText(url);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setCopying(false);
  };

  return (
    <div className="meeting-footer" style={{ bottom: isDesktop ? 0 : 55 }}>
      <div
        className={"exit-meeting-icon"}
        data-tip={"Leave Room"}
        onClick={() => {
          navigate("/chatrooms");
        }}
      >
        <FontAwesomeIcon icon={faDoorOpen} title="Leave" />
      </div>
      <div
        className={"meeting-icons " + (streamState.mic ? "active" : "")}
        data-tip={streamState.mic ? "Mute Audio" : "Unmute Audio"}
        onClick={micClick}
      >
        <FontAwesomeIcon
          icon={!streamState.mic ? faMicrophoneSlash : faMicrophone}
          title="Mute"
        />
      </div>
      <div
        className={"meeting-icons " + (streamState.video ? "active" : "")}
        data-tip={streamState.video ? "Hide Video" : "Show Video"}
        onClick={onVideoClick}
      >
        <FontAwesomeIcon icon={!streamState.video ? faVideoSlash : faVideo} />
      </div>
      {isDesktop ? (
        <div
          className={"meeting-icons " + (streamState.screen ? "active" : "")}
          data-tip="Share Screen"
          onClick={onScreenClick}
        >
          <FontAwesomeIcon icon={streamState.screen ? faEyeSlash : faDesktop} />
        </div>
      ) : null}
      {/* <div
        className={"meeting-icons " + (activegame !== "none" ? "active" : "")}
        data-tip="Play Game"
        onClick={onGameClick}
      >
        <FontAwesomeIcon icon={faGamepad} />
      </div> */}
      <div
        className={"share-meeting-icon"}
        data-tip={"Share Room Link"}
        onClick={() => {
          copyLink();
        }}
      >
        {copying ? (
          <FontAwesomeIcon icon={faCheck} title="Share" />
        ) : (
          <FontAwesomeIcon icon={faChain} title="Share" />
        )}
      </div>
      <ReactTooltip />
    </div>
  );
};

export default MeetingFooter;
