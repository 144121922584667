"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import chevron from "../../graphics/icons/chevron.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";

const ConnectionsViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  connectionsViewportVisible = false,
  setConnectionsViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.profile.connections}
      viewportVisible={connectionsViewportVisible}
      setViewportVisible={setConnectionsViewportVisible}
    >
      <div style={{ height: 10 }} />
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          maxHeight: isDesktop ? 550 : "100%",
          overflowY: "scroll",
        }}
      >
        Coming soon!
      </div>
    </Viewport>
  );
};

export default ConnectionsViewport;
