import React, { useRef, useState, useEffect } from "react";
import MeetingFooter from "../MeetingFooter/MeetingFooter.component";
import Participants from "../Participants/Participants.component";
import { connect } from "react-redux";
import { setMainStream, updateUser, setGame } from "../../store/actioncreator";
import { useWindowDimensions } from "../../utils/CustomHooks";
import GameArea from "../GameArea/GameArea";

const MainScreen = (props) => {
  const participantRef = useRef(props.participants);
  const participants = props.participants;

  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width > 650;

  const [activegame, setActiveGame] = useState("none");

  const [ingame, setIngame] = useState(false);
  const [players, setPlayers] = useState({});
  const [playerOrder, setPlayerOrder] = useState([]);

  const onMicClick = (micEnabled) => {
    if (props.stream) {
      props.stream.getAudioTracks()[0].enabled = micEnabled;
      props.updateUser({ audio: micEnabled });
    }
  };
  const onVideoClick = (videoEnabled) => {
    if (props.stream) {
      props.stream.getVideoTracks()[0].enabled = videoEnabled;
      props.updateUser({ video: videoEnabled });
    }
  };

  useEffect(() => {
    participantRef.current = props.participants;
  }, [props.participants]);

  const updateStream = (stream) => {
    for (let key in participantRef.current) {
      const sender = participantRef.current[key];
      if (sender.currentUser) continue;
      const peerConnection = sender.peerConnection
        .getSenders()
        .find((s) => (s.track ? s.track.kind === "video" : false));
      peerConnection.replaceTrack(stream.getVideoTracks()[0]);
    }
    props.setMainStream(stream);
  };

  const onScreenShareEnd = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    localStream.getVideoTracks()[0].enabled = Object.values(
      props.currentUser
    )[0].video;

    localStream.getVideoTracks()[0].addEventListener("ended", () => {
      // console.log("screensharing has ended");
    });

    updateStream(localStream);

    props.updateUser({ screen: false });
  };

  const onScreenClick = async () => {
    let mediaStream;
    if (navigator.getDisplayMedia) {
      mediaStream = await navigator.getDisplayMedia({ video: true });
    } else if (navigator.mediaDevices.getDisplayMedia) {
      mediaStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
    } else {
      mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { mediaSource: "screen" },
      });
    }

    mediaStream.getVideoTracks()[0].onended = onScreenShareEnd;

    updateStream(mediaStream);

    props.updateUser({ screen: true });
  };

  const onGameClick = async (gameName) => {
    if (!props.game) {
      props.setGame(gameName);
    } else {
      props.setGame(null);
      setIngame(false);
    }
  };

  useEffect(() => {
    props.gameRef.child("activegame").on("value", async (snap) => {
      setActiveGame(snap.val());
    });
  }, [ingame]);

  return (
    <div>
      <div
        style={{
          display: isDesktop ? "flex" : "block",
          flexDirection: isDesktop ? "row-reverse" : "column",
          textAlign: "center",
          margin: 0,
          marginTop: -10,
          paddingBottom: 60,
        }}
      >
        {!!activegame && activegame !== "none" && !!props.currentUser ? (
          <div style={{ flex: 4 }}>
            <GameArea
              currentUser={props.currentUser}
              participantRef={props.participantRef}
              gameRef={props.gameRef}
              players={players}
              playerOrder={playerOrder}
              ingame={ingame}
            />
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            flex: 6,
            position: !isDesktop && "absolute",
            top: !isDesktop && activegame !== "none" && 370,
            overflowY: !isDesktop && "scroll",
            maxHeight:
              !isDesktop && activegame !== "none"
                ? dimensions.height - 500
                : dimensions.height - 185,
            margin: "auto",
          }}
        >
          <Participants />
        </div>
      </div>

      <MeetingFooter
        onScreenClick={onScreenClick}
        onMicClick={onMicClick}
        onVideoClick={onVideoClick}
        onGameClick={onGameClick}
        onScreenShareEnd={onScreenShareEnd}
        gameRef={props.gameRef}
        participantRef={props.participantRef}
        setPlayers={setPlayers}
        setPlayerOrder={setPlayerOrder}
        setIngame={setIngame}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stream: state.mainStream,
    participants: state.participants,
    currentUser: state.currentUser,
    game: state.game,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMainStream: (stream) => dispatch(setMainStream(stream)),
    updateUser: (user) => dispatch(updateUser(user)),
    setGame: (newGame) => dispatch(setGame(newGame)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
