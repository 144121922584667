"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import Loading from "../Loading/Loading";

import H1 from "../Text/H1";

import avable_wordmark from "../../graphics/branding/avable_wordmark.svg";
import google from "../../graphics/logos/google.png";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";

const AuthenticationViewport = ({
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  activePage = null,
  setActivePage = {},
  authenticationViewportVisible = false,
  setAuthenticationViewportVisible = {},
  noticeViewportVisible = false,
  setNoticeViewportVisible = {},
}) => {
  const navigate = useNavigate();

  const [authorizationMode, setAuthorizationMode] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const handleCallbackResponse = async (email) => {
    const requestBody = {
      identifier: email,
      password: password,
      federated: true,
    };

    // console.log(requestBody);

    setIsAuthenticating(true);

    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/signin`,
        requestBody,
        requestConfig
      )
      .then(async (res) => {
        if (
          res.data.account.newaccount ||
          res.data.account.newaccount === null ||
          res.data.account.newaccount === undefined
        ) {
          await axios
            .post(
              `${process.env.REACT_APP_EMAILS_API_URL}/authenticate`,
              { message: "create", to: res.data.account.email },
              requestConfig
            )
            .then(async () => {
              console.log(res.data.account.id);
              await axios
                .post(
                  `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${res.data.account.id}`,
                  { newaccount: false },
                  requestConfig
                )
                .then((res2) => {
                  updateCredentials(res2.data.account);
                  setActivePage("profile");
                  navigate(`/${res2.data.account.username}`);
                  setAuthenticationViewportVisible(false);
                });
            });
        } else {
          updateCredentials(res.data.account);
          setActivePage("profile");
          navigate(`/${res.data.account.username}`);
          setAuthenticationViewportVisible(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const signinGoogle = () => {
    const attachSignin = (element) => {
      auth2.attachClickHandler(
        element,
        {},
        (googleUser) => {
          const email = googleUser.getBasicProfile().getEmail();
          handleCallbackResponse(email);
        },
        (error) => {}
      );
    };

    var auth2;
    gapi.load("auth2", function () {
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      auth2 = gapi.auth2.init({
        client_id: `${process.env.REACT_APP_OAUTH2_CLIENT_ID}.apps.googleusercontent.com`,
        ux_mode: "popup",
        callback: handleCallbackResponse,
      });
      attachSignin(document.getElementById("SigninDiv"));
    });
  };

  useEffect(() => {
    signinGoogle();
  }, [credentials]);

  // var accessToken = gapi.auth.getToken().access_token;

  const submitHandler = async (event) => {
    event.preventDefault();
    if (identifier.trim() === "" || password.trim() === "") {
      setErrorMessage(translation.authentication.error_missing_fields);
      return;
    }
    setErrorMessage(null);
    const requestConfig = {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    const requestBody = {
      identifier: identifier.toLowerCase(),
      password: password,
    };

    // console.log(requestBody);

    setIsAuthenticating(true);

    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/${
          !!authorizationMode ? "signup" : "signin"
        }`,
        requestBody,
        requestConfig
      )
      .then(async (res) => {
        if (
          res.data.account.newaccount ||
          res.data.account.newaccount === null ||
          res.data.account.newaccount === undefined
        ) {
          await axios
            .post(
              `${process.env.REACT_APP_EMAILS_API_URL}/authenticate`,
              { message: "create", to: res.data.account.email },
              requestConfig
            )
            .then(async () => {
              await axios
                .post(
                  `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${res.data.account.id}`,
                  { newaccount: false },
                  requestConfig
                )
                .then((res2) => {
                  updateCredentials(res2.data.account);
                  setActivePage("profile");
                  navigate(`/${res2.data.account.username}`);
                  setAuthenticationViewportVisible(false);
                });
            });
        } else {
          updateCredentials(res.data.account);
          setActivePage("profile");
          navigate(`/${res.data.account.username}`);
          setAuthenticationViewportVisible(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 10,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(183, 81, 224,.975)",
        textAlign: "center",
      }}
    >
      <img src={avable_wordmark} style={{ marginTop: 20, height: 48 }} />
      <div
        style={{
          position: "fixed",
          top: "33%",
          bottom: "50%",
          left: "50%",
          margin: "auto",
          justifyContent: "center",
          textAlign: "center",
          transform: "translate(-50%, 0%)",
        }}
      >
        <H2 style={{ marginBottom: 20, color: "#ffffff" }}>
          {!!authorizationMode
            ? translation.authentication.sign_up
            : translation.authentication.sign_in}
        </H2>

        <form
          onSubmit={isAuthenticating ? null : submitHandler}
          style={{
            textAlign: "center",
            justifyContent: "center",
            margin: "auto",
            opacity: isAuthenticating ? "50%" : "100%",
          }}
        >
          <input
            className="textinput"
            style={{
              width: 330,
              margin: 10,
              padding: 10,
              borderStyle: "solid",
              borderRadius: 8,
              borderColor: "#ffffff",
            }}
            type="text"
            value={identifier}
            placeholder={
              !!authorizationMode
                ? translation.authentication.email
                : translation.authentication.email_or_username
            }
            maxLength="100"
            onChange={(event) => setIdentifier(event.target.value)}
          />
          <br />
          <input
            className="textinput"
            style={{
              width: 330,
              margin: 10,
              padding: 10,
              borderStyle: "solid",
              borderRadius: 8,
              borderColor: "#ffffff",
            }}
            type="password"
            value={password}
            placeholder={translation.authentication.password}
            maxLength="24"
            onChange={(event) => setPassword(event.target.value)}
          />
          <div
            style={{
              display: "flex",
              margin: "auto",
              width: 355,
              marginBottom: 50,
              marginTop: 10,
            }}
          >
            <input
              className="textinput"
              type="submit"
              value={
                !!authorizationMode
                  ? translation.authentication.sign_up
                  : translation.authentication.sign_in
              }
              style={{
                cursor: "pointer",
                marginRight: 14,
                width: "100%",
                padding: 8,
                fontWeight: 600,
                fontSize: 14,
                borderRadius: 8,
                backgroundColor: "#000000",
                borderColor: "#000000",
                borderStyle: "solid",
                color: "#ffffff",
              }}
            />
            <Button
              type="solid"
              style={{
                backgroundColor: "#ffffff",
                justifyContent: "space-between",
                width: "150%",
                opacity: isAuthenticating ? "50%" : "100%",
              }}
              onClick={() => {
                if (!isAuthenticating) {
                  signinGoogle();
                }
              }}
              id="SigninDiv"
            >
              <div
                style={{
                  margin: "auto",
                  display: "flex",
                  width: "fit-content",
                }}
              >
                <div style={{}}>
                  <img
                    src={google}
                    style={{ width: 16, height: 16, marginTop: 1 }}
                  />
                </div>
                <span
                  style={{
                    color: "#000000",
                    fontSize: 14,
                    marginLeft: 10,
                  }}
                >
                  {translation.authentication.google_signin}
                </span>
              </div>
            </Button>
          </div>
          {isAuthenticating && !errorMessage ? (
            <Loading
              style={{
                filter: "brightness(1000%)",
                width: 20,
                height: 20,
                zIndex: 20,
                marginTop: -10,
                marginBottom: 20,
              }}
            />
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "auto",
              width: 350,
            }}
          >
            <div>
              <P style={{ color: "#ffffff" }}>
                {!!authorizationMode
                  ? translation.authentication.account_string
                  : translation.authentication.no_account_string}
              </P>
              <Button
                style={{
                  textAlign: "center",
                  margin: "auto",
                  width: 150,
                  color: "#ffffff",
                  fontStyle: "italic",
                  opacity: isAuthenticating ? "50%" : "100%",
                }}
                onClick={() => {
                  if (!isAuthenticating) {
                    setAuthorizationMode(!authorizationMode);
                  }
                }}
              >
                {!!authorizationMode
                  ? translation.authentication.sign_in_here
                  : translation.authentication.sign_up_here}
              </Button>
            </div>
            <div>
              <P style={{ color: "#ffffff" }}>
                {translation.authentication.forgot_password}
              </P>
              <Button
                style={{
                  textAlign: "center",
                  margin: "auto",
                  width: 150,
                  color: "#ffffff",
                  fontStyle: "italic",
                  opacity: isAuthenticating ? "50%" : "100%",
                }}
                onClick={() => {
                  if (!isAuthenticating) {
                    setNoticeViewportVisible("forgotpassword");
                  }
                }}
              >
                {translation.authentication.request_a_recovery}
              </Button>
            </div>
          </div>
          {errorMessage && !isAuthenticating ? (
            <P
              style={{
                color: "#EB5757",
                backgroundColor: "rgba(255,255,255,.75)",
                fontWeight: 600,
                padding: 4,
                marginTop: 20,
                borderRadius: 8,
                width: 350,
                textAlign: "center",
                margin: "auto",
              }}
            >
              {errorMessage}
            </P>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default AuthenticationViewport;
