"use es6";

import { useState, useEffect, useReducer } from "react";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";

import { countryCodeEmoji } from "country-code-emoji";

import P from "../components/Text/P";
import H1 from "../components/Text/H1";
import H3 from "../components/Text/H3";
import H2 from "../components/Text/H2";

import verified from "../graphics/icons/verified.svg";
import Button from "../components/Buttons/Button";
import HR from "../components/Text/HR";
import Bold from "../components/Text/Bold";
import Loading from "../components/Loading/Loading";
import Connection from "../components/Connections/Connection";
import UnknownPage from "./UnknownPage";

const ProfilePage = ({
  isDesktop = null,
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  dimensions = {},
  translation = null,
  setEditViewportVisible = {},
  setPurchaseViewportVisible = {},
  setPasswordViewportVisible = {},
  setUpgradeViewportVisible = {},
  setNoticeViewportVisible = {},
  setBadgeViewportVisible = {},
  setBadgesViewportVisible = {},
  setInterestsViewportVisible = {},
  setConnectionsViewportVisible = {},
  editTimestamp = null,
  setActivePage = {},
}) => {
  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const location = useLocation();
  const username = useLocation().pathname.split("/")[1];
  const [profile, setProfile] = useState(null);
  const isOwner = !!profile && profile.id === credentials.id;
  const [connectButtonState, setConnectButtonState] = useState(0);
  const [userNotFound, setUserNotFound] = useState(false);

  const getCredentials = async () => {
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        requestConfig
      )
      .then((res) => {
        if (res.data.id === credentials.id) {
          updateCredentials(res.data);
        }
      })
      .catch((error) => {});
  };

  const getProfile = async () => {
    setProfile(null);
    axios
      .get(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${username}`,
        requestConfig
      )
      .then((res) => {
        setProfile(res.data);

        if (!!res.data) {
          if (res.data.id === credentials.id) {
            updateCredentials(res.data);
          }
        } else {
          setUserNotFound(true);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getCredentials();
  }, []);

  useEffect(() => {
    getProfile();
  }, [editTimestamp, username]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const sendRequest = async () => {
    setConnectButtonState(1);
    const requestBody = {
      action: {
        id: `${credentials.id}request`,
        sender: credentials.id,
        type: "request",
        data: {},
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${profile.id}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        let newConnections = { ...credentials.connections };
        newConnections[profile.id] = false;
        axios
          .post(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
            { connections: newConnections },
            requestConfig
          )
          .then((res) => {
            if (res.data.account.id === credentials.id) {
              updateCredentials(res.data.account);
            }
          });
      })
      .catch((error) => {});
  };

  const cancelRequest = async () => {
    setConnectButtonState(0);
    const requestBody = {
      activityId: `${credentials.id}request`,
      deleteActivitys: true,
    };
    axios
      .post(
        `${process.env.REACT_APP_ACTIVITYS_API_URL}/activitys/${profile.id}`,
        requestBody,
        requestConfig
      )
      .then((res) => {
        let newConnections = { ...credentials.connections };
        delete newConnections[profile.id];
        axios
          .post(
            `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
            { connections: newConnections },
            requestConfig
          )
          .then((res) => {
            if (res.data.account.id === credentials.id) {
              updateCredentials(res.data.account);
            }
          });
      })
      .catch((error) => {});
  };

  const dateToZodiac = (date) => {
    const x = date.getMonth() * 100 + date.getDate();
    return x >= 221 && x <= 319
      ? "♈️"
      : x >= 320 && x <= 420
      ? "♉️"
      : x >= 421 && x <= 520
      ? "♊️"
      : x >= 521 && x <= 622
      ? "♋️"
      : x >= 623 && x <= 722
      ? "♌️"
      : x >= 723 && x <= 922
      ? "♍️"
      : x >= 823 && x <= 922
      ? "♎️"
      : x >= 923 && x <= 1021
      ? "♏️"
      : x >= 1022 && x <= 1121
      ? "♐️"
      : x >= 1122 && x <= 19
      ? "♑️"
      : x >= 20 && x <= 118
      ? "♒️"
      : x >= 119 && x <= 220
      ? "♓️"
      : void 0;
  };

  const big5ToString = (translation, big5) => {
    let output = "";
    output += translation.big5.agreeableness[0] + ":";
    output += Number(big5["agreeableness"]).toString().substring(2, 3) + " ";
    output += translation.big5.conscientiousness[0] + ":";
    output +=
      Number(big5["conscientiousness"]).toString().substring(2, 3) + " ";
    output += translation.big5.extraversion[0] + ":";
    output += Number(big5["extraversion"]).toString().substring(2, 3) + " ";
    output += translation.big5.neuroticism[0] + ":";
    output += Number(big5["neuroticism"]).toString().substring(2, 3) + " ";
    output += translation.big5.openness[0] + ":";
    output += Number(big5["openness"]).toString().substring(2, 3);
    return output;
  };

  const interest_emojis = {
    business: "💸",
    food: "🥡",
    books_comics: "📚",
    art_diy: "🎨",
    fashion: "🧥",
    fitness: "👟",
    stem: "🔬",
    history_geography: "🗺",
    music: "🎸",
    sports: "⚽️",
    games: "🎲",
    movies_tv: "🎬",
  };

  return (
    <div
      style={{
        flex: 1,
        paddingBottom: 10,
        maxWidth: isDesktop ? dimensions.width - 360 : null,
      }}
    >
      {!!translation && !!credentials && !!profile ? (
        <div
          style={{
            marginTop: isDesktop ? 15 : 5,
            marginRight: 20,
            marginLeft: 20,
          }}
        >
          <div style={{ display: "flex", width: "100%", marginBottom: 0 }}>
            <div
              style={{
                display: "flex",
                width: isDesktop ? 450 : "100%",
                marginBottom: 50,
                marginTop: 10,
                marginRight: isDesktop ? 20 : 0,
              }}
            >
              <img
                style={{
                  width: 72,
                  height: 72,
                  borderRadius: 72,
                  aspectRatio: 1,
                  marginRight: 20,
                  objectFit: "cover",
                  borderWidth: 1,
                  borderColor: "#673794",
                  borderStyle: "solid",
                }}
                src={
                  profile.image ||
                  "https://icon-library.com/images/default-profile-icon/default-profile-icon-6.jpg"
                }
              />
              <div style={{ width: "100%" }}>
                <div>
                  <H2 style={{ marginBottom: 10 }}>@{profile.username}</H2>
                  {profile.verified ? (
                    <img
                      src={verified}
                      style={{ marginLeft: 4, marginBottom: 8 }}
                    />
                  ) : null}
                  {isOwner ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: 10,
                      }}
                    >
                      {!!profile.birthday ? (
                        <div
                          style={{
                            fontSize: 24,
                            marginTop: -2,
                            marginRight: 10,
                          }}
                        >
                          {dateToZodiac(new Date(profile.birthday))}
                        </div>
                      ) : null}
                      {!!profile.personality.big5 ? (
                        <div
                          style={{
                            borderRadius: 8,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "#673794",
                            fontSize: 12,
                            color: "#ffffff",
                            backgroundColor: "rgba(183, 81, 224,.975)",
                            padding: 4,
                            paddingTop: 4,
                            paddingBottom: 5,
                            paddingLeft: 8,
                            paddingRight: 8,
                            marginTop: 1,
                            marginBottom: "auto",
                            marginLeft: 0,
                          }}
                        >
                          {big5ToString(translation, profile.personality.big5)}
                        </div>
                      ) : null}
                      {!!profile.personality.politics ? (
                        <div
                          style={{
                            borderRadius: 8,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "#673794",
                            fontSize: 12,
                            color: "#ffffff",
                            backgroundColor: "rgba(183, 81, 224,.975)",
                            paddingTop: 4,
                            paddingBottom: 5,
                            paddingLeft: 8,
                            paddingRight: 8,
                            marginTop: 1,
                            marginBottom: "auto",
                            marginLeft: 10,
                          }}
                        >
                          {translation.politics[profile.personality.politics]}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <Button
                      type="solid"
                      style={{
                        fontSize: 14,
                        paddingTop: 5,
                        paddingRight: 10,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        marginBottom: 10,
                        width: 100,
                        color:
                          Object.keys(credentials.connections).includes(
                            `${profile.id}`
                          ) || connectButtonState !== 0
                            ? credentials.connections[`${profile.id}`] ===
                                true || connectButtonState === 2
                              ? "#ffffff"
                              : "#555555"
                            : "#ffffff",
                        backgroundColor:
                          Object.keys(credentials.connections).includes(
                            `${profile.id}`
                          ) || connectButtonState !== 0
                            ? credentials.connections[`${profile.id}`] ===
                                true || connectButtonState === 2
                              ? "rgba(183, 81, 224,.975)"
                              : "#cccccc"
                            : "#000000",
                      }}
                      onClick={() => {
                        if (
                          credentials.connections[`${profile.id}`] === true ||
                          connectButtonState === 2
                        ) {
                          setNoticeViewportVisible(`disconnect:${profile.id}`);
                        } else if (
                          credentials.connections[`${profile.id}`] === false ||
                          connectButtonState === 1
                        ) {
                          cancelRequest(profile.id);
                        } else {
                          sendRequest();
                        }
                      }}
                    >
                      {Object.keys(credentials.connections).includes(
                        `${profile.id}`
                      ) || connectButtonState !== 0
                        ? credentials.connections[`${profile.id}`] === true ||
                          connectButtonState === 2
                          ? translation.profile.connected
                          : translation.profile.requested
                        : translation.profile.connect}
                    </Button>
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  {!!profile.name ? (
                    <Bold style={{ fontSize: 15, marginRight: 5 }}>
                      {profile.name}
                    </Bold>
                  ) : null}
                  {(!!profile.chatroompasscode && isOwner) ||
                  credentials.connections[`${profile.id}`] === true ? (
                    <Button
                      style={{
                        fontSize: 15,
                        color: "rgba(183, 81, 224,.975)",
                        marginBottom: 5,
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(profile.chatroompasscode);
                        alert(translation.miscellaneous.copied_to_clipboard);
                      }}
                    >
                      ({profile.chatroompasscode})
                    </Button>
                  ) : null}
                </div>
                {!!profile.bio ? (
                  <P style={{ marginBottom: 15 }}>{profile.bio}</P>
                ) : null}
                <div style={{ display: "flex" }}>
                  {!!profile.hometown &&
                  !!profile.hometown.name &&
                  profile.hometown.name.length > 0 ? (
                    <div style={{ marginRight: 20, marginBottom: 10 }}>
                      <P style={{ color: "#555555" }}>
                        {translation.profile.hometown}:
                      </P>

                      <div
                        style={{
                          display: "inline-block",
                          marginTop: -2,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            color: "#000000",
                          }}
                        >
                          {profile.hometown.name.substring(
                            0,
                            profile.hometown.name.length - 5
                          )}
                        </span>
                        <span
                          style={{
                            position: "relative",
                            fontSize: 18,
                            top: 2,
                            marginLeft: 5,
                            color: "#555555",
                          }}
                        >
                          {countryCodeEmoji(profile.hometown.name.slice(-2))}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {!!Object.keys(profile.languages).filter(
                    (key) => profile.languages[key]
                  ) &&
                  Object.keys(
                    Object.keys(profile.languages).filter(
                      (key) => profile.languages[key]
                    )
                  ).length > 0 ? (
                    <div style={{ marginBottom: 15 }}>
                      <P style={{ color: "#555555" }}>
                        {translation.profile.spoken_languages}:
                      </P>
                      {Object.keys(profile.languages)
                        .filter((key) => profile.languages[key])
                        .map((language, index) => (
                          <span key={index} style={{ fontSize: 14 }}>
                            {translation.languages[language]}
                            {index ===
                            Object.keys(
                              Object.keys(profile.languages).filter(
                                (key) => profile.languages[key]
                              )
                            ).length -
                              1
                              ? ""
                              : ", "}
                          </span>
                        ))}
                    </div>
                  ) : null}
                </div>

                {isOwner ? (
                  <Button
                    style={{
                      color: "rgba(183, 81, 224,.975)",
                    }}
                    onClick={() => {
                      setEditViewportVisible(true);
                    }}
                  >
                    <P style={{ fontWeight: 600 }}>
                      {translation.profile.edit_profile}
                    </P>
                  </Button>
                ) : null}
              </div>
            </div>

            {isDesktop && isOwner ? (
              <div
                style={{
                  margin: "auto",
                  position: "relative",
                  marginTop: 0,
                  marginBottom: 20,
                  flex: 1,
                  backgroundColor: "rgba(255,255,255,.33)",
                  borderRadius: 8,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#673794",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <Button
                  style={{ padding: 20 }}
                  onClick={() => {
                    setPurchaseViewportVisible(true);
                  }}
                >
                  <H3 style={{ fontWeight: 500 }}>
                    {translation.navigation.purchase_credits}
                  </H3>
                </Button>
                <HR />

                <Button
                  style={{ padding: 20 }}
                  onClick={() => {
                    setPasswordViewportVisible(true);
                  }}
                >
                  <H3 style={{ fontWeight: 500 }}>
                    {translation.authentication.change_password}
                  </H3>
                </Button>
                <HR />

                <Button
                  style={{ padding: 20 }}
                  onClick={() => {
                    setUpgradeViewportVisible(true);
                  }}
                >
                  <H3 style={{ fontWeight: 500 }}>
                    {translation.navigation.upgrade_account}
                  </H3>
                </Button>
                <HR />

                <Button
                  style={{ padding: 20 }}
                  onClick={() => {
                    setNoticeViewportVisible("deleteaccount");
                  }}
                >
                  <H3 style={{ fontWeight: 500, color: "#EB5757" }}>
                    {translation.authentication.delete_account}
                  </H3>
                </Button>
              </div>
            ) : null}
          </div>

          {isOwner || credentials.connections[`${profile.id}`] === true ? (
            <div
              style={{
                display: isDesktop ? "flex" : "block",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: 10,
                  backgroundColor: "rgba(255,255,255,.33)",
                  borderRadius: 8,
                  marginRight: isDesktop ? 20 : null,
                  marginBottom: 20,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#673794",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <H3
                    style={{
                      margin: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    {translation.profile.interests}
                  </H3>
                  {isOwner ? (
                    <Button
                      type="solid"
                      style={{
                        fontSize: 14,
                        paddingTop: 5,
                        paddingRight: 10,
                        paddingBottom: 5,
                        paddingLeft: 10,
                      }}
                      onClick={() => {
                        setInterestsViewportVisible(true);
                      }}
                    >
                      {translation.button.manage}
                    </Button>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    margin: 10,
                  }}
                >
                  {!!Object.keys(profile.interests).length ? (
                    Object.keys(profile.interests)
                      .filter((key) => profile.interests[key])
                      .filter((key) => key !== "avg")
                      .map((interest, index) => (
                        <div
                          key={index}
                          style={{
                            textAlign: "center",
                            fontSize: 12,
                            borderRadius: 8,
                            paddingTop: 5,
                            paddingRight: 10,
                            paddingBottom: 5,
                            paddingLeft: 10,
                            margin: 5,
                            height: 100,
                            color: "#000000",
                            backgroundColor: "#eeeff0",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "#ccc",
                            alignItems: "center",
                          }}
                        >
                          <P style={{ fontSize: 48 }}>
                            {interest_emojis[interest]}
                          </P>
                          {translation.interests[interest]}
                        </div>
                      ))
                  ) : (
                    <P
                      style={{
                        margin: "auto",
                        marginTop: 10,
                        padding: 10,
                        color: "#888888",
                      }}
                    >
                      {translation.miscellaneous.nothing_here}
                    </P>
                  )}
                </div>
                <br />
              </div>

              <div
                style={{
                  flex: 1,
                  padding: 10,
                  backgroundColor: "rgba(255,255,255,.33)",
                  borderRadius: 8,
                  marginBottom: 20,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#673794",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <H3
                    style={{
                      margin: 10,
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    {translation.profile.badges}
                  </H3>
                  {isOwner ? (
                    <Button
                      type="solid"
                      style={{
                        fontSize: 14,
                        paddingTop: 5,
                        paddingRight: 10,
                        paddingBottom: 5,
                        paddingLeft: 10,
                      }}
                      onClick={() => {
                        setBadgesViewportVisible(true);
                      }}
                    >
                      {translation.button.manage}
                    </Button>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    margin: 10,
                  }}
                >
                  {!!Object.keys(profile.badges).length ? (
                    Object.keys(profile.badges).map((badge, index) => (
                      <div key={index}></div>
                    ))
                  ) : (
                    <P
                      style={{
                        margin: "auto",
                        marginTop: 10,
                        padding: 10,
                        color: "#888888",
                      }}
                    >
                      {translation.miscellaneous.nothing_here}
                    </P>
                  )}
                </div>
                <br />
              </div>
            </div>
          ) : null}

          <div
            style={{
              flex: 1,
              backgroundColor: "rgba(255,255,255,.33)",
              padding: 10,
              borderRadius: 8,
              marginBottom: 20,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#673794",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: 10,
                marginTop: 10,
              }}
            >
              <H3
                style={{
                  margin: 10,
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {translation.profile.connections}
              </H3>
              {isOwner ? (
                <Button
                  type="solid"
                  style={{
                    fontSize: 14,
                    paddingTop: 5,
                    paddingRight: 10,
                    paddingBottom: 5,
                    paddingLeft: 10,
                  }}
                  onClick={() => {
                    setConnectionsViewportVisible(true);
                  }}
                >
                  {translation.button.manage}
                </Button>
              ) : null}
            </div>
            {isOwner || credentials.connections[`${profile.id}`] === true ? (
              <div
                style={{
                  display: "flex",
                  overflowX: "scroll",
                  marginBottom: 10,
                }}
              >
                {!!Object.keys(profile.connections).filter(
                  (key) => profile.connections[key]
                ).length ? (
                  Object.keys(profile.connections)
                    .filter((key) => profile.connections[key])
                    .map((connection, index) => (
                      <Connection
                        key={index}
                        credentials={credentials}
                        updateCredentials={updateCredentials}
                        translation={translation}
                        isPreview={true}
                        id={connection}
                        setActivePage={setActivePage}
                      />
                    ))
                ) : (
                  <P
                    style={{
                      margin: "auto",
                      marginTop: 20,
                      marginBottom: 10,
                      padding: 10,
                      color: "#888888",
                    }}
                  >
                    {translation.miscellaneous.nothing_here}
                  </P>
                )}
              </div>
            ) : (
              <div
                style={{
                  margin: 10,
                  marginBottom: 20,
                  fontStyle: "italic",
                  color: "rgba(183, 81, 224,.975)",
                }}
              >
                {`${translation.profile.connect_with} @${profile.username} ${translation.profile.to_see}`}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          {!userNotFound ? (
            <Loading
              style={{
                position: "fixed",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <UnknownPage />
          )}
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
