import firebase from "firebase/compat/app";

import "firebase/compat/database";

var firebaseConfig = {
  apiKey: "AIzaSyBSocffefrWRe1I_idTSdWHMiFh4P1FzX0",
  databaseURL: "https://avable-co-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase;

var firepadRef = firebase.database().ref();

const data = await JSON.parse(localStorage.getItem("avable-credentials"));

export const userData = !!data ? data : { username: `guest${Date.now()}` };
const urlparams = new URLSearchParams(window.location.search);
const id = urlparams.get("id");

if (id) {
  firepadRef = firepadRef.child(id);
} else {
  firepadRef = firepadRef.push();
}

export default firepadRef;
