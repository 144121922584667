"use es6";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import Button from "../Buttons/Button";
import H1 from "../Text/H1";

import chevron from "../../graphics/icons/chevron.svg";
import H3 from "../Text/H3";
import H2 from "../Text/H2";
import P from "../Text/P";
import HR from "../Text/HR";
import Viewport from "./Viewport";

const InterestsViewport = ({
  isDesktop = null,
  children = "[insert text]",
  credentials = null,
  setCredentials = {},
  updateCredentials = {},
  language = null,
  translation = null,
  interestsViewportVisible = false,
  setInterestsViewportVisible = {},
  setEditTimestamp = {},
}) => {
  const navigate = useNavigate();

  const [interests, setInterests] = useState(
    !!Object.keys(credentials.interests).length
      ? credentials.interests
      : {
          business: false,
          food: false,
          books_comics: false,
          art_diy: false,
          fashion: false,
          fitness: false,
          stem: false,
          history_geography: false,
          music: false,
          sports: false,
          games: false,
          movies_tv: false,
        }
  );

  const requestConfig = {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY,
    },
  };

  const editInterests = async () => {
    const requestBody = {
      interests: interests,
    };

    await axios
      .post(
        `${process.env.REACT_APP_ACCOUNTS_API_URL}/accounts/${credentials.id}`,
        requestBody,
        requestConfig
      )
      .then(async (res) => {
        updateCredentials(res.data.account);
        setEditTimestamp(Date.now());
      })
      .then(async () => {
        setInterestsViewportVisible(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const interest_list = {
    business: [-9, -7],
    food: [5, 5],
    books_comics: [8, 2],
    art_diy: [7, -6],
    fashion: [1, -8],
    fitness: [-4, 7],
    stem: [-9, 3],
    history_geography: [-3, 1],
    music: [7, -3],
    sports: [-7, -4],
    games: [2, 6],
    movies_tv: [-4, 2],
  };

  const interest_emojis = {
    business: "💸",
    food: "🥡",
    books_comics: "📚",
    art_diy: "🎨",
    fashion: "🧥",
    fitness: "👟",
    stem: "🔬",
    history_geography: "🗺",
    music: "🎸",
    sports: "⚽️",
    games: "🎲",
    movies_tv: "🎬",
  };

  const getAverageCoordinates = (interests) => {
    let avg = [0, 0];
    Object.keys(interests)
      .filter((key) => interests[key])
      .filter((key) => key !== "avg")
      .forEach((interest) => {
        avg[0] += interest_list[interest][0];
        avg[1] += interest_list[interest][1];
      });

    avg[0] =
      avg[0] /
      (Object.keys(interests).filter((key) => interests[key]).length * 10);
    avg[1] =
      avg[1] /
      (Object.keys(interests).filter((key) => interests[key]).length * 10);

    return avg;
  };

  return (
    <Viewport
      isDesktop={isDesktop}
      heading={translation.profile.interests}
      viewportVisible={interestsViewportVisible}
      setViewportVisible={setInterestsViewportVisible}
    >
      <div style={{ height: 10 }} />
      <div
        style={{
          margin: "auto",
          justifyContent: "center",
          textAlign: "center",
          maxHeight: isDesktop ? 550 : "100%",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            margin: "auto",
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
            flexWrap: "wrap",
            marginBottom: 100,
          }}
        >
          {Object.keys(interest_list).map((interest, index) => (
            <div
              key={index}
              style={{
                cursor: "pointer",
                fontSize: 12,
                borderRadius: 8,
                paddingTop: 5,
                paddingRight: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                margin: 5,
                width: 100,
                height: 100,
                color: !!interests[interest] ? "#ffffff" : "#000000",
                backgroundColor: !!interests[interest]
                  ? "rgba(183, 81, 224,.975)"
                  : isDesktop
                  ? "#eeeff0"
                  : "#ffffff",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: !!interests[interest] ? "#673794" : "#ccc",
                alignItems: "center",
              }}
              onClick={() => {
                if (
                  Object.keys(
                    Object.keys(interests).filter((key) => interests[key])
                  ).length < 5 ||
                  !!interests[interest]
                ) {
                  setInterests((prevInterests) => {
                    return {
                      ...prevInterests,
                      [interest]: !!!interests[interest],
                      avg: getAverageCoordinates({
                        ...prevInterests,
                        [interest]: !!!interests[interest],
                      }),
                    };
                  });
                } else {
                  alert(translation.profile.too_many_interests_alert);
                }
              }}
            >
              <P style={{ fontSize: 48 }}>{interest_emojis[interest]}</P>
              {translation.interests[interest]}
            </div>
          ))}
        </div>

        <Button
          type="solid"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 20,
            textAlign: "center",
            margin: "auto",
            width: 315,
          }}
          onClick={() => {
            editInterests();
          }}
        >
          {translation.button.save}
        </Button>
      </div>
    </Viewport>
  );
};

export default InterestsViewport;
