"use es6";

import { useNavigate } from "react-router-dom";

import P from "../Text/P";

import profile from "../../graphics/icons/profile.svg";
import home from "../../graphics/icons/home.svg";
import chatrooms from "../../graphics/icons/chatrooms.svg";
import signin from "../../graphics/icons/signin.svg";
import explore from "../../graphics/icons/explore.svg";

const Footer = ({
  credentials = null,
  translation = null,
  activePage = null,
  setActivePage = {},
  setAuthenticationViewportVisible = {},
  setActivityViewportVisible = {},
  ...buttonProps
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(183, 81, 224,.975)",
        position: "fixed",
        bottom: 0,
        borderTopWidth: 1,
        borderTopStyle: "solid",
        borderTopColor: "#673794",
        alignItems: "center",
        padding: 5,
        display: "flex",
        zIndex: 5,
      }}
    >
      <div
        style={{
          cursor: "pointer",
          margin: "auto",
          textAlign: "center",
          justifyContent: "center",
          opacity: activePage === "" ? 1 : 0.5,
        }}
        onClick={() => {
          navigate("/");
          if (activePage === "chatrooms") {
            window.location.reload();
          }
          setActivePage("");
        }}
      >
        <img
          src={home}
          style={{
            width: 20,
            height: 20,
          }}
        />

        <P style={{ color: "#ffffff", fontSize: 14 }}>
          {translation.navigation.home}
        </P>
      </div>

      <div
        style={{
          cursor: "pointer",
          margin: "auto",
          textAlign: "center",
          justifyContent: "center",
          opacity: activePage === "explore" ? 1 : 0.5,
        }}
        onClick={() => {
          navigate("/explore");
          if (activePage === "explore") {
            window.location.reload();
          }
          setActivePage("explore");
        }}
      >
        <img src={explore} style={{ width: 20, height: 20 }} />

        <P style={{ color: "#ffffff", fontSize: 14 }}>
          {translation.navigation.explore}
        </P>
      </div>

      {!!credentials ? (
        <div
          style={{
            cursor: "pointer",
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            opacity: activePage === "chatrooms" ? 1 : 0.5,
          }}
          onClick={() => {
            navigate("/chatrooms");
            if (activePage === "chatrooms") {
              window.location.reload();
            }
            setActivePage("chatrooms");
          }}
        >
          <img src={chatrooms} style={{ width: 20, height: 20 }} />

          <P style={{ color: "#ffffff", fontSize: 14 }}>
            {translation.navigation.chatrooms}
          </P>
        </div>
      ) : (
        <div
          style={{
            cursor: "pointer",
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            opacity: activePage === "signin" ? 1 : 0.5,
          }}
          onClick={() => {
            if (activePage === "chatrooms") {
              window.location.reload();
            }
            setAuthenticationViewportVisible(true);
          }}
        >
          <img src={signin} style={{ width: 20, height: 20 }} />

          <P style={{ color: "#ffffff", fontSize: 14 }}>
            {translation.authentication.sign_in}
          </P>
        </div>
      )}
      {!!credentials ? (
        <div
          style={{
            cursor: "pointer",
            margin: "auto",
            textAlign: "center",
            justifyContent: "center",
            opacity: activePage === "profile" ? 1 : 0.5,
          }}
          onClick={() => {
            navigate(`/${credentials.username}`);
            if (activePage === "chatrooms") {
              window.location.reload();
            }
            setActivePage("profile");
          }}
        >
          <img src={profile} style={{ width: 20, height: 20 }} />

          <P style={{ color: "#ffffff", fontSize: 14 }}>
            {translation.navigation.profile}
          </P>
        </div>
      ) : null}
    </div>
  );
};

export default Footer;
